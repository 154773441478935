import React, { useContext, useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Button,
  Label,
} from 'reactstrap';
import ReactDatetime from 'react-datetime';

import { AppContext } from 'store/app.context';
import { Formik } from 'formik';
import schema from './validators';
import useFocusService from 'services/focus/focus.service';
import useFilesService from 'services/files/files.services';
import { toast } from 'react-toastify';
import useHomeService from 'services/home/home.service';
const ModalAddLink = ({ modalShow, closeModal, idproject }) => {
  const { languageState } = useContext(AppContext);

  const { createQuickLink } = useFocusService();
  const { getQuickLinks } = useHomeService();

  return (
    <Modal isOpen={modalShow} toggle={closeModal} centered>
      <ModalHeader
        close={
          <button className="close " onClick={closeModal}>
            ×
          </button>
        }
        className="mb-0"
      >
        {languageState.translation.HOME.DASHBOARD.ADD_QUICK}
      </ModalHeader>
      <ModalBody className="mt-0 pt-0">
        <Formik
          initialValues={{
            name: '',
            link: '',
          }}
          validationSchema={schema(languageState.translation.FORM_VALIDATION)}
          onSubmit={async (values) => {
            let result;
            if (idproject) {
              result = await createQuickLink({
                ...values,
                projet: idproject,
              });
            } else {
              result = await createQuickLink({
                ...values,
              });
            }
            if (result && result.status === 201) {
              closeModal();
             // await getMisions();
              await getQuickLinks();
              toast.success(
                languageState.translation.FOCUS.DASHBOARD.LINK_ADDED,
                {
                  position: 'bottom-right',
                  hideProgressBar: false,
                  autoClose: 4000,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'dark',
                }
              );
            }
          }}
        // closeModal();
        >
          {({
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <div>
              <Label className="mt-n4">
                {' '}
                {languageState.translation.COMMON.NAME}
              </Label>
              <Input
                placeholder={languageState.translation.COMMON.SHORTCUTNAME}
                name="name"
                onChange={handleChange}
              />
              <small className="text-red">
                {' '}
                {errors.name && touched.name && errors.name}
              </small>
              <Label className="d-block mt-1">
                {' '}
                {languageState.translation.COMMON.LINK}
              </Label>
              <Input
                name="link"
                onChange={handleChange}
              />
              {!idproject && (
                <Label className="d-block mt-1">
                  {' '}
                  {languageState.translation.COMMON.CATEGORY}
                </Label>
              )}
              {!idproject && (
                <Input
                  name="category"
                  onChange={handleChange}
                />
              )}

              <button
                className="btn btn-exprimary mt-2 ml-auto d-block"
                onClick={() => handleSubmit()}
                disabled={isSubmitting}
              >
                {languageState.translation.COMMON.BUTTONS.SAVE}
              </button>
            </div>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default ModalAddLink;
