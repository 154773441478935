import React, { useContext, useState } from 'react';
import { Form, Formik } from 'formik';
import { ModalBody, Input, Row, Col, Button, ModalFooter, FormGroup, Tooltip } from 'reactstrap';
import useUserService from 'services/profile/user.service';
import { AppContext } from 'store/app.context';
import STEPPER_STATE from 'store/stepper';
import { secondStepperScreenSchema } from './schema';
import UserCard from 'components/user-card/user-card';
import { FileUploader } from "react-drag-drop-files";
import useFilesService from 'services/files/files.services';
import CropAvatarEditor from 'components/CropAvatarEditor/CropAvatarEditor';
import { toast } from 'react-toastify';
import countries from 'i18n-iso-countries';
import Select from 'react-select';
import en from 'i18n-iso-countries/langs/en.json';
import nl from 'i18n-iso-countries/langs/nl.json';
const fileTypes = ["JPG", "PNG", "GIF", "JPEG"];

countries.registerLocale(en);
countries.registerLocale(nl);

const SecondStepperScreen = ({ timeRemaining }) => {
  const { dispatchStepperState, languageState, userState } = useContext(AppContext);
  const { addAddress, updateBasicInfos, autoUpdateUserInfo, inviteMultipleUsers, checkIsValidEUVat, createStipeCustomer } = useUserService();
  const { uploadFile } = useFilesService();
  const [image, setImage] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipAdresses, setTooltipAdresses] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const translatedCountries = countries.getNames(languageState?.defaultLanguage); // Get translated country names
  const countryOptions = Object.entries(translatedCountries).map(([code, name]) => ({
    value: code,
    label: name
  }));

  const [isLoading, setIsLoading] = useState(false)
  const [isValidVat, setIsValidVat] = useState(true)
  const [EUVatResponse, setEUVatresponse] = useState({
    address: "",
    countryCode: "",
    name: ''
  })
  const [stripeData, setStripeData] = useState({
    city: '',
    houseNumber: '',
    street: '',
    countryCode: '',
    postalCode: ''
  })
  const [fieldValues, SetFieldValues] = useState({
    profileImage: null,
    VATNumber: '',
    companyName: '',
    street: '',
    houseNumber: '',
    postalCode: '',
    city: '',
    country: "",
    freelancersEmails: ""
  })

  function isValidVAT(input) {
    const cleanedInput = input.replace(/[\s.]+/g, '');
    const vatRegex = /^[A-Z]{2}[A-Z0-9]{10,12}$/;
    return { valid: vatRegex.test(cleanedInput), cleaned: cleanedInput };
  }

  const checkValidEUVat = async (vat) => {
    const countryCode = vat.slice(0, 2);
    const vatCode = vat.slice(2);
    setIsLoading(true);
    const response = await checkIsValidEUVat(countryCode, vatCode);
    setIsValidVat(response.valid);
    setEUVatresponse(response);

    if (response.valid) {
      const parsedAddress = parseAddress(response?.address);
      setStripeData({
        countryCode: response?.countryCode,
        city: parsedAddress.city,
        street: parsedAddress.street,
        houseNumber: parsedAddress.houseNumber,
        postalCode: parsedAddress.postalCode,
      });

      SetFieldValues((prev) => ({
        ...prev,
        companyName: response.name,
        street: parsedAddress.street,
        houseNumber: parsedAddress.houseNumber,
        postalCode: parsedAddress.postalCode,
        city: parsedAddress.city,
      }));
    } else {
      setStripeData({
        countryCode: "",
        city: "",
        street: "",
        houseNumber: "",
        postalCode: "",
      });

      SetFieldValues((prev) => ({
        ...prev,
        companyName: "",
        street: "",
        houseNumber: "",
        postalCode: "",
        city: "",
      }));
    }

    setIsLoading(false);
  };

  function parseAddress(input) {
    if(input)
    {

      const [firstLine, secondLine] = input.split('\n');
      const firstSpaceIndex = firstLine.indexOf(' ');
      
      const street = firstLine.substring(0, firstSpaceIndex).trim();
      const houseNumber = firstLine.substring(firstSpaceIndex + 1).trim();
      const postalCodeEndIndex = secondLine.indexOf(' ');
      
      const postalCode = secondLine.substring(0, postalCodeEndIndex).trim();
      const city = secondLine.substring(postalCodeEndIndex + 1).trim(); 
      return { street, houseNumber, postalCode, city };
    }
    return {
      street:"", houseNumber:"", postalCode:"", city:""
    }
  }

  const handleImageChange = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setImage(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const onProfilePictureChange = async (file) => {
    const result = await uploadFile(file);

    if (result && result.status === 201) {
      const reqData = {
        profileImage: result.data.filename,
      };
      const result2 = await updateBasicInfos(reqData);
      if (result2 && result2.status === 200) {
        autoUpdateUserInfo();
        setImage(null)
      }
    }
  };

  const handleSecondStepperSubmit = async (values) => {
    const { profileImage, freelancersEmails, ...rest } = values
    if (userState?.userInfo?.profileImage) {// Send multiple invitations 
       if (freelancersEmails) {
         const dataToSend = freelancersEmails.split('\n').map((el) => { return { email: el } })
         const sendInvitations = await inviteMultipleUsers(dataToSend);
         if (sendInvitations && sendInvitations.status === 201) {
           toast.success(languageState.translation.ALERT.SUCCESS_ADD_USER, {
             position: 'bottom-right',
             autoClose: 4000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
             theme: 'light',
           });
         }
       }
      await createStipeCustomer(stripeData);

       const result = await addAddress({ ...rest, type: 'NORMAL' });
       if (result && result.status === 201) {
         autoUpdateUserInfo();
         dispatchStepperState({
           type: STEPPER_STATE.types.CHANGE_CURRENT_STEPPER_STATE,
           currentStepPayload: 3,
         });
     }
    };
  }

  return (
    <Formik
      initialValues={fieldValues}
      enableReinitialize
      validationSchema={secondStepperScreenSchema(
        languageState.translation.FORM_VALIDATION
      )}
      onSubmit={(values) => {
        handleSecondStepperSubmit(fieldValues);
      }}
    >
      {({ errors, touched, values, handleChange, setFieldValue, handleBlur, handleSubmit }) => (
        <Form>
          <ModalBody className='pt-0'>
            <Row>
              <Col xs="12" sm="12" md="12" lg="4" xl="4">
                <div className='font-weight-bolder my-3'>
                  {languageState.translation.STEPPER.PROFILE_LOOK}
                </div>
                <div className='mb-2'>
                  <UserCard
                    openModalAddNote={() => { }}
                    openModalDelete={() => { }}
                    openModalAddUserTags={() => { }}
                    user={userState.userInfo}
                    key={userState.userInfo.id}
                    mode={'ONBOARDING'}
                    openModalAddUserGroup={() => { }} module={undefined} />
                </div>
              </Col>
              <Col xs="12" sm="12" md="12" lg="8" xl="8">
                <Row>
                  <Col xs="12" sm="12" md="12" lg="12" xl="12"
                    className={isSubmitted && !userState?.userInfo?.profileImage ? "error-input" : ""}
                  >
                    <div className='font-weight-bolder my-3'>{languageState.translation.STEPPER.BASIC_INFO_SECTION_3}</div>
                    <div className="dropzone dropzone-multiple profile-image-upload my-3" id="dropzone-multiple">
                      <div className="fallback">
                        <FileUploader
                          name="file"
                          label={languageState.translation.COMMON.UPLOAD_PROFILE_PICTURE_LABEL}
                          handleChange={handleImageChange}
                          types={fileTypes}
                        />
                      </div>
                    </div>
                    {
                      image && <CropAvatarEditor
                        image={image}
                        setImage={setImage}
                        onProfilePictureChange={(file) => onProfilePictureChange(file)} />
                    }
                  </Col>
                  {isSubmitted && !userState?.userInfo?.profileImage && (
                    <div className="invalid-feedback">{languageState.translation.FORM_VALIDATION.REQUIRED_FIELD}</div>
                  )}
                  <Col xs="12" sm="12" md="12" lg="12" xl="12">
                    <div className='d-flex font-weight-bolder my-3'>
                      {languageState.translation.STEPPER.BASIC_INFO_SECTION_4}
                      <div id="TooltipAdresses">
                        <i className='fa fa-info-circle ml-2 cursor-pointer' />
                      </div>
                      <Tooltip
                        isOpen={tooltipAdresses}
                        target="TooltipAdresses"
                        toggle={() => {
                          setTooltipAdresses(!tooltipAdresses)
                        }}
                      >
                        {languageState.translation.STEPPER.ADDRESSES_INFO}
                      </Tooltip>
                    </div>
                  </Col>
                  <Col xs="12" sm="12" md="6" lg="6" xl="6"  >
                    <Row lg={12} className='d-flex justify-content-between'>
                      <Col lg={5} md={12}>
                        <Select
                          options={countryOptions}
                          className={`select-country ${errors.country && touched.country ? "error-input" : ""}`}
                          value={countryOptions.find((el) => el.label === fieldValues.country)}
                          placeholder={languageState.translation.PROFILE.COUNTRY}
                          onChange={(e) => {
                            const countryCode = e.value;
                            SetFieldValues({
                              ...fieldValues,
                              country: e.label,
                              VATNumber: `${countryCode}${fieldValues.VATNumber.slice(2)}`,
                            });
                            setFieldValue("country", e.label);
                            setFieldValue("VATNumber", `${countryCode}${values.VATNumber.slice(2) || ""}`);

                            const { valid, cleaned } = isValidVAT(`${countryCode}${values.VATNumber.slice(2)}`);
                            setIsValidVat(valid);

                            if (valid) {
                              checkValidEUVat(cleaned);
                            }else {
                              setStripeData({
                                countryCode: "",
                                city: "",
                                street: "",
                                houseNumber: "",
                                postalCode: "",
                              });
                        
                              SetFieldValues((prev) => ({
                                ...prev,
                                companyName: "",
                                street: "",
                                houseNumber: "",
                                postalCode: "",
                                city: "",
                              }))
                            }
                          }}
                          styles={{
                            container: (base) => ({
                              ...base,
                              width: '100%',
                              height: '100%'
                            }),
                            control: (base) => ({
                              ...base,
                              width: "100%",
                            }),
                          }}
                        />
                      </Col>
                      <Col lg={7} md={12}>
                        <div className='d-flex align-items-center'>
                          <Input
                            className={`${errors.VATNumber || !isValidVat ? "error-input" : ""}`}
                            placeholder={languageState.translation.PROFILE.VAT_NUMBER}
                            name="VATNumber"
                            value={fieldValues.VATNumber}
                            onChange={(e) => {
                              const vatValue = e.target.value;
                              const vatPrefix = vatValue.slice(0, 2);

                              const matchingCountry = countryOptions.find((option) => option.value === vatPrefix);
                              if (matchingCountry) {
                                SetFieldValues({
                                  ...fieldValues,
                                  VATNumber: vatValue,
                                  country: matchingCountry.label,
                                });
                                setFieldValue("VATNumber", vatValue.trim());
                                setFieldValue("country", matchingCountry);
                              } else {
                                SetFieldValues({
                                  ...fieldValues,
                                  VATNumber: vatValue,
                                  country: null,
                                });
                                setFieldValue("VATNumber", vatValue);
                                setFieldValue("country", null);
                              }

                              const { valid, cleaned } = isValidVAT(vatValue.trim());
                              setIsValidVat(valid);

                              if (valid) {
                                checkValidEUVat(cleaned);
                              }
                              else {
                                setStripeData({
                                  countryCode: "",
                                  city: "",
                                  street: "",
                                  houseNumber: "",
                                  postalCode: "",
                                });
                          
                                SetFieldValues((prev) => ({
                                  ...prev,
                                  companyName: "",
                                  street: "",
                                  houseNumber: "",
                                  postalCode: "",
                                  city: "",
                                }))
                              }
                            }}
                          />
                          {isLoading && (
                            <div className="spinner-border text-primary" style={{
                              width: '1rem',
                              height: '1rem',
                              marginLeft: '-1.5rem'
                            }}>
                            </div>
                          )
                          }
                        </div>
                        <small className="text-red">
                          {(errors && errors.VATNumber && touched.VATNumber && errors.VATNumber || !isValidVat) && <span className="visually-hidden text-red">Not a valid EU VAT</span>}
                        </small>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs="12" sm="12" md="6" lg="6" xl="6">
                    <FormGroup>
                      <Input
                        className={`w-100 ${errors.companyName && touched.companyName ? "error-input" : ""}`}
                        placeholder={languageState.translation.PROFILE.COMPANY_NAME}
                        value={fieldValues.companyName}
                        name="companyName"
                        onChange={(e) => {
                          setFieldValue("companyName", e.target.value)
                          SetFieldValues({ ...fieldValues, companyName: e.target.value })
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="12" md="6" lg="6" xl="6">
                    <FormGroup>
                      <Input
                        className={`w-100 ${errors.street && touched.street ? "error-input" : ""}`}
                        placeholder={languageState.translation.PROFILE.STREET}
                        value={fieldValues.street}
                        name="street"
                        onChange={(e) => {
                          setFieldValue("street", e.target.value)
                          SetFieldValues({ ...fieldValues, street: e.target.value })
                        }} />
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="12" md="6" lg="6" xl="6">
                    <FormGroup>
                      <Input
                        className={`w-100 ${errors.houseNumber && touched.houseNumber ? "error-input" : ""}`}
                        placeholder={languageState.translation.PROFILE.HOUSENUMBER}
                        value={fieldValues.houseNumber}
                        name="houseNumber"
                        onChange={(e) => {
                          setFieldValue("houseNumber", e.target.value)
                          SetFieldValues({ ...fieldValues, houseNumber: e.target.value })
                        }} />
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="12" md="6" lg="6" xl="6">
                    <FormGroup>
                      <Input
                        className={`w-100 ${errors.postalCode && touched.postalCode ? "error-input" : ""}`}
                        placeholder={languageState.translation.PROFILE.POSTAL_CODE}
                        value={fieldValues.postalCode}
                        name="postalCode"
                        onChange={(e) => {
                          SetFieldValues({ ...fieldValues, postalCode: e.target.value })
                          setFieldValue("postalCode", e.target.value)
                        }} />
                      <small className="text-red">
                        {errors && errors.postalCode && touched.postalCode && errors.postalCode}
                      </small>
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="12" md="6" lg="6" xl="6">
                    <FormGroup>
                      <Input
                        className={`w-100 ${errors.city && touched.city ? "error-input" : ""}`}
                        placeholder={languageState.translation.COMMON.CITY}
                        value={fieldValues.city}
                        name="city"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="text-red">
                        {errors && errors.city && touched.city && errors.city}
                      </small>
                    </FormGroup>
                  </Col>
                  {/* 
                  <Col xs="12" sm="12" md="6" lg="6" xl="6">
                    <CountryDropdown
                      className={`form-control w-100 ${errors.country && touched.country ? "error-input" : ""}`}
                      value={values.country}
                      placeholder="België"
                      name="country"
                      onChange={(value) => {
                        setFieldValue('country', value);
                      }}
                    /> */}
                  {/*<Select
                      options={countryOptions}
                      className={`w-100 ${errors.country && touched.country ? "error-input" : ""}`}
                      value={countryOptions.find((el) => el.value === values.country)}
                      placeholder={languageState.translation.PROFILE.COUNTRY}
                      menuPlacement='top'
                      onChange={(e) => setFieldValue('country', e.value)}
                    />*/}
                  {/*
                  </Col>
                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                    <div className='d-flex font-weight-bolder my-3'>
                    {languageState.translation.STEPPER.BASIC_INFO_SECTION_5}
                      <div id="TooltipExample">
                        <i className='fa fa-info-circle ml-2 cursor-pointer' />
                      </div>
                      <Tooltip
                        isOpen={tooltipOpen}
                        target="TooltipExample"
                        toggle={() => {
                          setTooltipOpen(!tooltipOpen)
                        }}
                      >
                        {languageState.translation.STEPPER.INVITE_FREELANCERS_INFO}
                      </Tooltip>
                    </div>
                    <Input
                      className={`w-100 ${errors.freelancersEmails && touched.freelancersEmails ? "error-input" : ""}`}
                      placeholder={languageState.translation.STEPPER.SECOND_STEP_PLACEHOLDERS.INVITE_FREELANCERS}
                      name="freelancersEmails"
                      type="textarea"
                      onChange={handleChange}
                    />
                    {errors.freelancersEmails && touched.freelancersEmails && (
                      <div className="invalid-feedback">{errors.freelancersEmails}</div>
                    )}
                  </Col> */}
                </Row>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className='d-flex justify-content-end'>

            <Button
              className="btn-next-link"
              color="link"
              onClick={() => {
                setIsSubmitted(true);
                handleSubmit();
              }}
            >
              {languageState.translation.COMMON.ONBOARDING_BTN_STEP2_NEXT}
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
};

export default SecondStepperScreen;
