import classnames from 'classnames';
import Contact from 'components/chat/contact';
import Conversation from 'components/chat/conversation';
import ChatUsersList from 'components/chat/users-list';
import { ROOM_TYPES } from 'config/core';
import { MODULES } from 'config/core';
import { CHAT_EVENTS } from 'config/core';
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Alert, Card, CardHeader, Col, Container, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import useChatService from 'services/chat/chat.service';
import useUserService from 'services/profile/user.service';
import { AppContext } from 'store/app.context';
import { ChatSocketContext } from 'store/chat.context';
import SHARED_STATE from 'store/shared';
import { searchRooms } from 'utils';

const Chat = ({ location }) => {
  const [search, setSearch] = useState(false);
  const { languageState, chatState, dispatchSharedState, userState } = useContext(AppContext);
  const { updateBasicInfos, getUserInfo } = useUserService()
  const [activeRoom, setActiveRoom] = useState();
  const [usersListModalOpened, setUsersListModalOpened] = useState(false);
  const [creationMode, setCreationMode] = useState(false);
  const [selectedUsersForCreationMode, setSelectedUsersForCreationMode] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  /** Chat Hook */
  const { getRooms, createConversation } = useChatService();

  const ws = useContext(ChatSocketContext);

  /** Chat State */

  useEffect(() => {
    const getRoooms = async() => {
      await getRooms();
    }
    getRoooms()
  }, [userState.userInfo]);
  useEffect(() => {
    dispatchSharedState({
      module: MODULES.CHAT,
      type: SHARED_STATE.types.SET_CURRENT_MODULE,
    });
  }, []);
  useEffect(() => {
    if (searchValue) {
      const filteredRooms = chatState?.rooms?.filter((item) =>
        searchRooms(searchValue, item)
      );
      setConversations(filteredRooms);
    } else {
      setConversations([]);
    }
  }, [searchValue]);
  const firstRoomCreate = (email) => {
    const user = chatState?.users?.find((data) => data.email === email);
    if (user) {
      startNewConversation([user.id], '');
    } else {
      startNewConversation([location.state.user.email], '');
    }
  };

  useEffect(() => {
    if (!activeRoom) {
      if (location?.state?.user) {
        if (chatState?.rooms?.length) {
          const room = chatState.rooms?.find((data) => {
            return (
              data.users_rooms?.length === 2 &&
              data.users_rooms?.find((dataUser) => {
                return dataUser.user.email === location.state.user.email;
              })
            );
          });

          if (room && !activeRoom) {
            setActiveRoom(room);

            window.history.replaceState({}, document.title);
          } else if (!room) {
            firstRoomCreate(location.state.user.email);
            window.history.replaceState({}, document.title);
          }
        } else {
          firstRoomCreate(location.state.user.email);
          window.history.replaceState({}, document.title);
        }
      } else if (chatState?.rooms?.length) setActiveRoom(chatState?.rooms[0]);
    }

    if (creationMode) {
      const selectedRoom = chatState?.rooms?.find((item) => {
        if (
          item?.users_rooms?.length ===
          selectedUsersForCreationMode?.length + 1
        ) {
          let counter = 1;
          item?.users_rooms?.forEach((user) => {
            if (selectedUsersForCreationMode?.includes(user?.user?.id))
              counter++;
          });
          if (counter === item?.users_rooms?.length) return item;
        }
      });
      if (selectedRoom) {
        setActiveRoom(selectedRoom);
      }
      setSelectedUsersForCreationMode([]);
      setCreationMode(false);
    }
  }, [chatState.rooms]);

  const openUsersModal = () => {
    setUsersListModalOpened(true);
  };

  const startNewConversation = async (targetUsers, name) => {
    ws.emit(CHAT_EVENTS.NEW_ROOM, {
      targetUsers,
      name,
      type: targetUsers?.length > 1 ? ROOM_TYPES.GROUP : ROOM_TYPES.DIRECT,
    });
    await onAddNewRoom(targetUsers);
  };

  const onAddNewRoom = (targetUsers) => {
    getRooms();
    setUsersListModalOpened(false);
    setSelectedUsersForCreationMode(targetUsers);
    setCreationMode(true);
  };

  const onAcceptCondition = async (e) => {
    const result = await updateBasicInfos({ acceptChatWarning: true })
    if (result && result.status === 200) {
      getUserInfo()
      toast.success(languageState.translation.ALERT.SUCCESS_ACCEPTION_CONDITION, {
        position: 'bottom-right',
        hideProgressBar: false,
        closeOnClick: true,
        autoClose: 4000,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    }
  }

  return (
    <Container fluid className="mt-4">
      {userState && userState.userInfo && !userState.userInfo.acceptChatWarning && (
        <Row>
          <Col className='col-12'>
            <Alert color="warning">
              <div>
                <h4 className='font-weight-bold text-white'>{languageState.translation.CHAT.PALATFORM_RULES_CONDITON_TITLE}</h4>
                <p className='font-weight-normal fs-1'>{languageState.translation.CHAT.PALATFORM_RULES_CONDITON_SUBTITLE}</p>
                <ul>
                  <li className='font-weight-bold fs-1'>{languageState.translation.CHAT.PALATFORM_RULES_CONDITON_1} <span className='font-weight-normal'>{languageState.translation.CHAT.PALATFORM_RULES_CONDITON_1_CONTACTS}</span></li>
                  <li className='font-weight-bold fs-1'>{languageState.translation.CHAT.PALATFORM_RULES_CONDITON_2}</li>
                  <li className='font-weight-bold fs-1'>{languageState.translation.CHAT.PALATFORM_RULES_CONDITON_3}</li>
                </ul>
                <p className='font-weight-normal fs-1 mb-2'> <span className='font-weight-bold'>{languageState.translation.CHAT.PALATFORM_RULES_CONDITON_CONSEQUENCE_TITLE}</span> {languageState.translation.CHAT.PALATFORM_RULES_CONDITON_CONSEQUENCE}</p>
              </div>
              <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id="customCheckRegisterGDPR"
                  type="checkbox"
                  onChange={onAcceptCondition}
                />
                <label
                  className="custom-control-label h-auto text-white"
                  htmlFor="customCheckRegisterGDPR"
                >
                  <span className="text-white fs-1 text-prewrap">
                    {languageState.translation.CHAT.CONDITION_CHAT}
                  </span>
                </label>
              </div>
            </Alert>
          </Col>
        </Row>
      )}
      <Row>
        <Col className="col-12 col-lg-4">
          <Card className="card-contacts chat-shadow bg-chat contact-chat-height">
            <CardHeader className="bg-chat pb-1">
              <FormGroup className="bg-chat">
                <InputGroup
                  className={classnames('input-group-merge', {
                    focused: search,
                  })}
                >
                  <Input
                    placeholder={languageState.translation.CHAT.SEARCH}
                    type="text"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    onFocus={(e) => setSearch(true)}
                    onBlur={(e) => setSearch(false)}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </CardHeader>
            <div className="d-flex flex-column w-100 p-3 overflow-y-auto contact-chat-height ">
              {!searchValue &&
                chatState.rooms &&
                chatState.rooms?.length > 0 &&
                chatState.rooms.map((data, index) => (
                  data && data.users_rooms && data.users_rooms.length > 1 ? (
                    <span key={index}>
                      <Contact
                        key={data?.id}
                        room={data}
                        onClick={() => setActiveRoom(data)}
                        isActive={activeRoom && data?.id === activeRoom?.id}
                      />
                      {index !== 0 && <hr className="m-1" />}{' '}
                    </span>
                  ) : null
                ))}
              {searchValue &&
                conversations &&
                conversations?.length > 0 &&
                conversations?.map((data, index) => (
                  <span>
                    <Contact
                      key={data?.id}
                      room={data}
                      onClick={() => setActiveRoom(data)}
                      isActive={activeRoom && data?.id === activeRoom?.id}
                    />
                    {index !== 0 && <hr className="m-1" />}{' '}
                  </span>
                ))}
              {chatState?.rooms && chatState?.rooms.length === 0 && (
                <div className="text-center">
                  {languageState.translation.CHAT.NO_MESSAGES_DATA}
                </div>
              )}
              <span
                className="btn btn-secondary mt-4"
                onClick={() => openUsersModal()}
              >
                {languageState.translation.CHAT.START_NEW_CONVERSATION}
              </span>
            </div>
          </Card>
        </Col>
        <Col className="col-12 col-lg-8">
          {activeRoom && <Conversation room={activeRoom} />}
        </Col>
      </Row>
      <input type="file" className="d-none" id="file" />
      <ChatUsersList
        location={location}
        opened={usersListModalOpened}
        closeModal={() => setUsersListModalOpened(false)}
        startNewConversation={(users, roomName) =>
          startNewConversation(users, roomName)
        }
      />
    </Container>
  );
};

export default Chat;
