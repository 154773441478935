import React, { useContext, useEffect, useState } from 'react';
// nodejs library that concatenates classes
import classnames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// reactstrap components
import { Collapse, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, ListGroup, Media, Navbar, NavItem, NavLink, Nav, Container, Col, Button, ButtonGroup } from 'reactstrap';
import { AppContext } from 'store/app.context';
import USER_STATE from 'store/user';
import { useHistory } from 'react-router-dom';
import DropwdownFlags from 'components/flags-dropdown';
import InnerNav from 'components/inner-nav';
import SHARED_STATE from 'store/shared';
import { DEFAULT_PRIVATE_PATH, MODULES } from 'config/core';
import APP_ROUTES from '../../../navigation/app.routes';
import Avatar from 'components/avatar/avatar';
import { IMG_PREFIX } from 'config/core';
import moment from 'moment';
import useUserService from 'services/profile/user.service';
import ModalPending from 'components/modalPendingInvitations/ModalPending';
import { isAuthorized } from 'utils';
import { toast } from 'react-toastify';
import CHAT_STATE from 'store/chat';
import AddFeedbackModal from 'components/ModalAddFeedback/modalAddFeedback';
const AdminNavbar = ({ theme, sidenavOpen, toggleSidenav }) => {
  const history = useHistory();

  const { languageState, dispatchUserState, userState, dispatchSharedState, chatState, notificationsState, dispatchChatState } = useContext(AppContext);
  const { getNotifications } = useUserService();

  const { userInfo } = userState;
  const [showModal, setShowModal] = useState(false);
  const [pagination, setPagination] = useState([]);
  const [bgColor, setBgColor] = useState('bg-exprimary');
  const [modalShow, setModalShow] = useState(false);


  // function that on mobile devices makes the search open
  const openSearch = () => {
    document.body.classList.add('g-navbar-search-showing');
    setTimeout(() => {
      document.body.classList.remove('g-navbar-search-showing');
      document.body.classList.add('g-navbar-search-show');
    }, 150);
    setTimeout(() => {
      document.body.classList.add('g-navbar-search-shown');
    }, 300);
  };

  useEffect(() => {
    if (chatState?.newMessage?.message?.author?.id !== userState?.userInfo?.cid) {
      setTimeout(() => {
        if (chatState && chatState.newMessage && chatState.newMessage.message && chatState.newMessage.message.author && chatState.newMessage.message.author.id !== userState?.userInfo?.id && !history?.location?.pathname?.includes('/chat')) {
          toast.dark(getToastContent(chatState.newMessage.message), {
            position: 'bottom-right',
            autoClose: 15000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
            style: {
              backgroundColor: '#4f4f4f', // Set the background color to #4f4f4f
            },
          });
        }
      }, 5000)
    }

  }, [chatState, userInfo])

  useEffect(() => {
    if (!isAuthorized()) return;
    getNotifications();
  }, []);
  useEffect(() => {
    getPaginate();
  }, [notificationsState]);

  const getToastContent = (message) => {
    return <div className='d-flex'>
      <div className='mr-2'>
        {message.author.photo_url ? (
          <span className="avatar avatar-sm rounded-circle">
            <img alt="..." src={`${IMG_PREFIX}${message.author.photo_url}`} />
          </span>
        ) : (
          <Avatar
            firstName={message.author.first_name}
            lastName={message.author.last_name}
            name={`${message.author.first_name} ${message.author.last_name}`}
            forNavbar={true}
            className="rounded-circle custom-avatar"
          />
        )}
      </div>
      <div>
        <div>{`${message.author.first_name} ${message.author.last_name.charAt(0)}`}</div>
        <small>{message.content}</small>
      </div>
    </div>
  }

  const onClickNotifications = () => {
    if (chatState && chatState.newMessage) {
      dispatchChatState({
        type: CHAT_STATE.types.SET_NEW_MESSAGE_NOTIFICATION,
        newMessage: null,
      });
      history.push(DEFAULT_PRIVATE_PATH + APP_ROUTES.CHAT)
    }
  }


  const onClickOpenChat = () => {
    history.push(DEFAULT_PRIVATE_PATH + APP_ROUTES.CHAT)
  }



  const getPaginate = () => {
    const listPage = [];
    if (
      notificationsState.notifications?.meta &&
      notificationsState.notifications?.meta.totalPages
    ) {
      if (notificationsState.notifications?.meta?.currentPage > 1) {
        listPage.push(notificationsState.notifications?.meta?.currentPage - 1);
      }
      listPage.push(notificationsState.notifications?.meta?.currentPage);
      if (
        notificationsState.notifications?.meta?.currentPage <
        notificationsState.notifications?.meta.totalPages
      ) {
        listPage.push(notificationsState.notifications?.meta?.currentPage + 1);
      }
      setPagination(listPage);
    }
  };

  // const nextPage = () => {
  //   if (
  //     notificationsState.notifications?.meta.currentPage <
  //     notificationsState.notifications?.meta.totalPages
  //   ) {
  //     const page = notificationsState.notifications?.meta.currentPage + 1;
  //     getNotifications(page);
  //     window.scrollTo(0, 0);
  //   }
  // };
  // const prevPage = () => {
  //   if (notificationsState.notifications?.meta?.currentPage > 1) {
  //     const page = notificationsState.notifications?.meta.currentPage - 1;
  //     getNotifications(page);
  //     window.scrollTo(0, 0);
  //   }
  // };

  // const hasPrev = () => {
  //   if (
  //     notificationsState.notifications?.meta &&
  //     notificationsState.notifications?.meta?.currentPage
  //   ) {
  //     if (notificationsState.notifications?.meta?.currentPage > 1) {
  //       return false;
  //     }
  //     return true;
  //   }
  // };

  // const hasNext = () => {
  //   if (
  //     notificationsState.notifications?.meta &&
  //     notificationsState.notifications?.meta.currentPage
  //   ) {
  //     if (
  //       notificationsState.notifications?.meta.currentPage <
  //       notificationsState.notifications?.meta.totalPages
  //     ) {
  //       return false;
  //     }
  //   }
  //   return true;
  // };

  const logout = (e) => {
    dispatchUserState({
      type: USER_STATE.types.RESET_USER_INFO,
    });
    dispatchSharedState({
      type: SHARED_STATE.types.SET_CURRENT_MODULE,
      module: MODULES.AUTH,
    });
    toast.dismiss();
    history.push('/login');
  };

  const pickColor = (sub) => {
    switch (sub.toLowerCase()) {
      case 'plat':
        if (bgColor !== 'bg-gradient-success')
          setBgColor('bg-gradient-success');
        break;
      case 'platinum':
        if (bgColor !== 'bg-gradient-success')
          setBgColor('bg-gradient-success');
        break;

      case 'gold':
        if (bgColor !== 'bg-gradient-warning')
          setBgColor('bg-gradient-warning');
        break;

      case 'silver':
        if (bgColor !== 'bg-gradient-light') setBgColor('bg-gradient-light');
        break;
      default:
        break;
    }
  };

  const getSubsbription = () => {
    if (userInfo?.subToUser?.length === 0) {
      const date =
        30 - moment(Date.now()).diff(moment(userInfo?.created_at), 'days');

      if (date > 0) {
        return 'Free trial: ' + date + ' Days Left';
      } else {
        if (bgColor != 'bg-gray') setBgColor('bg-gray');
        return 'Freemium';
      }
    } else if (userInfo?.subToUser?.find((data) => data.isExpired === false)) {
      const data = userInfo?.subToUser?.find(
        (data) => data.isExpired === false
      );
      pickColor(data?.subscription?.name);
      return data?.subscription?.name + ' subscription';
    } else {
      if (bgColor != 'bg-gray') setBgColor('bg-gray');
      return 'Freemium';
    }
  };

  return (
    <>
      <Navbar
        id="navbarbg"
        className={classnames(
          'navbar-top navbar-expand border-bottom zindex',
          { 'navbar-dark bg-exprimary': theme === 'dark' },
          { 'navbar-light bg-secondary': theme === 'light' }
        )}
      >
            

        <Container fluid>
        <div
                  className={classnames(
                    'pr-3 sidenav-toggler d-xl-none',
                    { active: sidenavOpen },
                    { 'sidenav-toggler-dark d-xl-none': theme === 'dark' }
                  )}
                  onClick={toggleSidenav}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
          <Collapse navbar isOpen={true}>
            <InnerNav module="HOME" />

          <Nav className="align-items-center ml-md-auto" navbar>
               <div     
                 className="btn btn-light p-2 px-6  cursor-pointer rounded"
                 onClick={() => setModalShow(true)}>
                      {languageState.translation.NAVBAR.FEEDBACK}
                 </div>

              {<button
                className={
                  'btn btn-lg btn-primary mr-3 btn-status text-capitalize d-none  ' +
                  bgColor
                }
                disabled
              >
                {getSubsbription()}
              </button>
              }
                
            
           
               

             {/*  <NavItem className="btn-lg btn-danger btn">
                <NavLink onClick={onClickOpenChat}>
                  <i className="ni ni-chat-round" />
                  <span>{languageState.translation.NAVBAR.CHAT}</span>
                </NavLink>
              </NavItem> */}

       


              <NavItem className="d-sm-none">
                <NavLink onClick={openSearch}>
                  <i className="ni ni-zoom-split-in" />
                </NavLink>
              </NavItem>
              <DropwdownFlags direction="down" color=""/>
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link c-pointer" color="" tag="a" onClick={onClickNotifications}>
                  <i className={`ni ni-bell-55${chatState && chatState.newMessage && chatState.newMessage.message && chatState.newMessage.message.author && chatState.newMessage.message.author.id !== userState?.userInfo?.cid && !history?.location?.pathname?.includes('/chat') ? " text-danger" : ""}`} />
                </DropdownToggle>
                {/* <DropdownMenu
                  className="dropdown-menu-xl py-0 overflow-hidden"
                  right
                >
                  {
                    notificationsState.notifications &&
                      notificationsState?.notifications.items &&
                      notificationsState?.notifications.items.length
                      ?
                      <>
                        <div className="px-3 py-3">
                          <h6 className="text-sm text-muted m-0">
                            {languageState.translation.NAVBAR.NOTIFICATION}
                          </h6>
                        </div>
                        <ListGroup flush>
                          {notificationsState?.notifications.items.map(
                            (notification, index) =>
                              notification.type === 'INVITATION' ||
                                notification.type === 'ACCEPT-INVITATION' ? (
                                <Col key={index} onClick={() => setShowModal(true)}>
                                  <div className="invitation-item">
                                    <h4>{notification.title}</h4>
                                    <p>
                                      {notification?.sender?.firstName}{' '}
                                      {notification?.sender?.lastName}{' '}
                                      {languageState.translation.HOME.DASHBOARD.NOTIFICATION}
                                    </p>
                                  </div>
                                </Col>
                              ) : (
                                <></>
                              )
                          )
                          }
                          {notificationsState?.notifications?.meta?.totalItems > 6 && (
                            <Container className="d-flex justify-content-center">
                              <ButtonGroup className="d-flex justify-content-center gap-2 mt-auto mb-3">
                                <Button
                                  className=" flex-grow-0 rounded-circle paginate-button"
                                  type="button"
                                  color="primary"
                                  onClick={() => prevPage()}
                                  disabled={hasPrev()}
                                >
                                  <i className="fas fa-angle-left"></i>
                                </Button>
                                {pagination &&
                                  pagination.map((data, index) => (
                                    <Button
                                      key={index}
                                      className={
                                        notificationsState.notifications?.meta
                                          .currentPage == data
                                          ? '  flex-grow-0 rounded-circle paginate-button active shadow-lg'
                                          : 'flex-grow-0 rounded-circle paginate-button shadow-lg'
                                      }
                                      color="primary"
                                      type="button"
                                      onClick={() => getNotifications(data)}
                                    >
                                      {data}
                                    </Button>
                                  ))}
                                <Button
                                  className=" flex-grow-0 rounded-circle paginate-button "
                                  type="button"
                                  color="primary"
                                  onClick={() => nextPage()}
                                  disabled={hasNext()}
                                >
                                  <i className="fas fa-angle-right"></i>
                                </Button>
                              </ButtonGroup>
                            </Container>
                          )}
                        </ListGroup>
                      </>
                      : <div className="d-flex justify-content-center p-4">
                        <span>{languageState.translation.HOME.DASHBOARD.NO_NOTIFICATIONS}</span>
                      </div>
                  }
                </DropdownMenu> */}
              </UncontrolledDropdown>
            </Nav> 
            <Nav className="align-items-center ml-auto ml-md-0" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                  {userState.userInfo && (
                    <Media className="align-items-center c-pointer">
                      {userState.userInfo.profileImage ? (
                        <span className="avatar avatar-sm rounded-circle">
                          <img
                            className='object-fit-cover'
                            alt="..."
                            src={`${IMG_PREFIX}${userState.userInfo.profileImage}`}
                          />
                        </span>
                      ) : (
                        <Avatar
                          firstName={userState.userInfo.firstName}
                          lastName={userState.userInfo.lastName}
                          name={userState.userInfo.name}
                          forNavbar={true}
                          className="rounded-circle custom-avatar"
                        />
                      )}
                      <Media className="ml-2 d-none d-lg-block">
                        <span className="mb-0 text-sm font-weight-bold">
                          {userInfo &&
                            `${userInfo.firstName} ${userInfo.lastName}`}
                        </span>
                      </Media>
                    </Media>
                  )}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    onClick={(e) => history.push(DEFAULT_PRIVATE_PATH + APP_ROUTES.SETTINGS)}
                  >
                    <i className="ni ni-settings-gear-65" />
                    <span>{languageState.translation.NAVBAR.SETTINGS}</span>
                  </DropdownItem>
                  <DropdownItem
                    onClick={(e) =>
                      history.push(DEFAULT_PRIVATE_PATH + APP_ROUTES.CHAT)
                    }
                  >
                    <i className="ni ni-chat-round" />
                    <span>{languageState.translation.NAVBAR.CHAT}</span>
                  </DropdownItem>

                  <DropdownItem
                    onClick={(e) => {
                      history.push('/private/connect/network/profile/' + userInfo?.id);
                    }}
                  >
                    <i className="ni ni-support-16" />
                    <span>
                      {languageState.translation.NAVBAR.VISIT_MY_PROFILE}
                    </span>
                  </DropdownItem>
                  <DropdownItem onClick={(e) => logout(e)}>
                    <i className="ni ni-user-run" />
                    <span>{languageState.translation.NAVBAR.LOGOUT}</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
          <ModalPending
            modalShow={showModal}
            closeModal={() => {
              setShowModal(false);
            }}
          />
      <AddFeedbackModal modalShow={modalShow} closeModal={() => setModalShow(false)}/>
        </Container>
      </Navbar>
    </>
  );
};

AdminNavbar.defaultProps = {
  toggleSidenav: () => { },
  sidenavOpen: false,
  theme: 'dark',
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(['dark', 'light']),
};

export default AdminNavbar;
