import React, { useState } from 'react';
import "./dragAndDropUpload.css";

export const DragAndDropUploadMultiple = ({ onFilesUpload, onDeleteFile }) => {
    const [dragOver, setDragOver] = useState(false);
    const [fileNames, setFileNames] = useState([]);
    const [filePreviews, setFilePreviews] = useState([]);



    const handleDragOver = (event) => {
        event.preventDefault();
        setDragOver(true);
    };

    const handleDragLeave = () => {
        setDragOver(false);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        setDragOver(false);

        const files = Array.from(event.dataTransfer.files);
        if (files.length > 0) {
            const newFileNames = files.map((file) => file.name);
            const newPreviews = files.map((file) =>
                file.type.startsWith('image/') ? URL.createObjectURL(file) : null
            );
            setFileNames((prevFileNames) => [...prevFileNames, ...newFileNames]);
            setFilePreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);
            onFilesUpload(files);
        }
    };

    const handleFilesSelect = (event) => {
        event.preventDefault();
        console.log("herrrre")
        const files = Array.from(event.target.files);
        if (files.length > 0) {
            const newFileNames = files.map((file) => file.name);
            const newPreviews = files.map((file) =>
                file.type.startsWith('image/') ? URL.createObjectURL(file) : null
            );
            setFileNames((prevFileNames) => [...prevFileNames, ...newFileNames]);
            setFilePreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);
            onFilesUpload(files);
        }
    };

    const handleRemoveFile = (fileName, index) => {

        const updatedFileNames = fileNames.filter((name) => name !== fileName);
        const updatedFilePreviews = filePreviews.filter((_, idx) => idx !== index);
        setFileNames(updatedFileNames);
        onDeleteFile(index);
        setFilePreviews(updatedFilePreviews);
    };

    return (
        <div
            className={`drag-drop-upload ${dragOver ? 'drag-over' : ''}`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
        >
            <input
                type="file"
                id="fileInput"
                style={{ display: 'none' }}
                onChange={handleFilesSelect}
                multiple={true}
            />

            {fileNames.length > 0 ? (
                <div className="uploaded-files d-flex gap-2 align-items-center flex-wrap">
                    <label htmlFor="fileInput" className="upload-label">
                        <i className='fa fa-plus'></i>
                    </label>
                    {fileNames.map((fileName, index) => (
                        <div key={fileName} className="file-item">
                            {filePreviews[index] ? (
                                <div className="file-preview">
                                    <img
                                        src={filePreviews[index]}
                                        alt={fileName}
                                        className="image-preview"
                                    />
                                </div>
                            ) : (
                                <div className="file-icon">
                                    <span>📄</span>
                                </div>
                            )}
                            <a
                                //href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleRemoveFile(fileName, index);
                                }}
                            >
                                Remove
                            </a>
                        </div>
                    ))}

                </div>
            ) : <label htmlFor="fileInput" className="upload-label">
                Drag & Drop files here or click to upload     </label>
            }

        </div>
    );
};
