import { Field, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { CountryDropdown } from 'react-country-region-selector';
import { Row, Col, FormGroup, Input, Card, CardBody, CardHeader, Label } from 'reactstrap';
import useUserService from 'services/profile/user.service';
import { AppContext } from 'store/app.context';
import BillingAddressBasicInformation from './billing-address-basic-information';
import { basicInformationAddressSchema } from 'pages/profile/schema';
import { toast } from 'react-toastify';

const AddressBasicInformation = () => {
  const { languageState, userState } = useContext(AppContext);

  const { addAddress, getAddAddress } = useUserService();

  const [initialValues, setValues] = useState({
    VATNumber: '',
    companyName: '',
    street: '',
    houseNumber: '',
    postalCode: '',
    city: '',
    country: 'Belgium',
  });

  const [isBillingAddressSame, setIsBillingAddressSame] = useState(false);

  useEffect(() => {
    getAddAddress();
  }, []);

  useEffect(() => {
    const normalOrBothAddress = userState?.basicInformation?.address?.filter(
      (address) => address?.type === 'NORMAL' || address?.type === 'BOTH'
    );

    if (normalOrBothAddress?.length) {
      setValues({
        ...normalOrBothAddress[0],
      });
    }
  }, [userState]);

  useEffect(() => {
    const normalOrBothAddress = userState?.basicInformation?.address?.filter(
      (address) => address?.type === 'NORMAL' || address?.type === 'BOTH'
    );

    if (normalOrBothAddress?.length) {
      if (normalOrBothAddress[0]?.type === 'NORMAL') {
        setIsBillingAddressSame(false);
      } else if (normalOrBothAddress[0]?.type === 'BOTH') {
        setIsBillingAddressSame(true);
      }
    }
  }, [userState]);

  const submitForm = async (values) => {
    if (
      userState?.basicInformation?.address &&
      userState?.basicInformation?.address?.length === 0
    ) {
      const result = await addAddress({
        ...values,
        type: isBillingAddressSame ? 'BOTH' : 'NORMAL',
      });
      console.log('result', result);
      if (result && result.status === 201) {
        toast.success(languageState.translation.ALERT.ADDRESS_SAVED, {
          position: 'bottom-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      }
    }

    const normalAddressFound = userState?.basicInformation?.address?.filter(
      (address) => address?.type === 'NORMAL' || address?.type === 'BOTH'
    );
    if (normalAddressFound?.length) {
      const result = await addAddress({
        id: normalAddressFound[0]?.id,
        ...values,
        type: isBillingAddressSame ? 'BOTH' : 'NORMAL',
      });
      if (result && result.status === 201) {
        toast.success(languageState.translation.ALERT.ADDRESS_SAVED, {
          position: 'bottom-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      }
    }
  };
  return (
    <>
      {/*<Card>
        <CardHeader>
          <Row className="align-items-center">
            <Col xs="6">
              <h3 className="mb-0">Mijn officieel adres</h3>
            </Col>
            <Col xs="6" className='d-flex justify-content-end'>
              <Label check>
                <Input
                  type="checkbox"
                  checked={isBillingAddressSame}
                  onChange={(e) => {
                    setIsBillingAddressSame(e.target.checked);
                  }}
                />
                Gebruik dezelfde gegevens voor mijn factuuradres
              </Label>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={basicInformationAddressSchema}
            onSubmit={(values) => submitForm(values)}
          >
            {({
              errors,
              touched,
              values,
              handleSubmit,
              isSubmitting,
              handleChange,
            }) => (
             <div>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <Field
                          className="form-control"
                          id="input-vat-number"
                          placeholder={'VAT-number'}
                          type="text"
                          name="VATNumber"
                        />
                        {errors.VATNumber && touched.VATNumber && (
                          <div className="invalid-feedback">
                            {errors.VATNumber}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <Field
                          className="form-control"
                          id="input-company-name"
                          placeholder={'Company name'}
                          type="text"
                          name="companyName"
                        />
                        {errors.companyName && touched.companyName && (
                          <div className="invalid-feedback">
                            {errors.companyName}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <Field
                          className="form-control"
                          id="input-street"
                          placeholder={'Street'}
                          type="text"
                          name="street"
                        />
                        {errors.street && touched.street && (
                          <div className="invalid-feedback">
                            {errors.street}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <Field
                          className="form-control"
                          id="input-house-number"
                          placeholder={'House number'}
                          type="text"
                          name="houseNumber"
                        />
                        {errors.houseNumber && touched.houseNumber && (
                          <div className="invalid-feedback">
                            {errors.houseNumber}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <Field
                          className="form-control"
                          id="input-postal-code"
                          placeholder={'Postal Code'}
                          type="text"
                          name="postalCode"
                        />
                        {errors.postalCode && touched.postalCode && (
                          <div className="invalid-feedback">
                            {errors.postalCode}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <Input
                          placeholder={languageState.translation.COMMON.CITY}
                          name="city"
                          value={values.city}
                          onChange={handleChange}
                        />
                         <RegionDropdown
                          className="form-control"
                          id="input-city"
                          placeholder={'City'}
                          type="text"
                          value={values.city}
                          name="city"
                          onChange={(val) => {
                            setValues({
                              ...values,
                              city: val,
                            });
                          }}
                          country={values.country}
                        /> 
                        {values.city && touched.city && (
                          <div className="invalid-feedback">{errors.city}</div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Col md="6">
                    <div className="pl-lg-4">
                      <FormGroup>
                        <CountryDropdown
                          className="form-control"
                          id="input-country"
                          placeholder={
                            languageState.translation.PROFILE.COUNTRY
                          }
                          type="text"
                          name="country"
                          value={values.country}
                          onChange={(val) => {
                            setValues({
                              ...values,
                              country: val,
                            });
                          }}
                        />
                        {errors.country && touched.country && (
                          <div className="invalid-feedback">
                            {errors.country}
                          </div>
                        )}
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <button
                  className="btn bg-exprimary mt-2 ml-auto d-block"
                  onClick={() => handleSubmit()}
                  disabled={isSubmitting}
                >
                  {languageState.translation.COMMON.BUTTONS.SAVE_INFO}
                </button>
              </div>
            )}
          </Formik>
        </CardBody>
      </Card>*/}
      {!isBillingAddressSame && <BillingAddressBasicInformation />}
    </>
  );
};

export default AddressBasicInformation;
