import { useContext } from "react";
import { AppContext } from "store/app.context";
import { Formik } from "formik";
import { Modal, ModalHeader, ModalBody, Input, Label } from 'reactstrap';
import { toast } from 'react-toastify';
import useDashboardService from 'services/dashboard/dashboard.service';
import useFeedbackService from 'services/feedback/feedback.service';
import schema from "./validators";
import './feedbackModal.css';
import Avatar from "components/avatar/avatar";
import { IMG_PREFIX } from 'config/core';
import useFilesService from "services/files/files.services";



const AddFeedbackModal = ({ modalShow, closeModal }) => {

  const { languageState, userState } = useContext(AppContext);
  const { addFeedback } = useFeedbackService();




  const addFeedbackHandler = async (values) => {

    const response = await addFeedback(values);
    if (response && response.status === 201) {
      toast.success(languageState.translation.SIDEBAR.THANKS_FOR_FEEDBACK, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      closeModal();
    }

  }
  const validationSchemaMessages = {
    REQUIRED_FIELD: 'This field is required'
  };

  return (
    <Modal isOpen={modalShow} toggle={closeModal} centered>
      <ModalHeader
        close={
          <button className="close " onClick={closeModal}>
            <i className='fa fa-times' />
          </button>
        }
        className="mb-0"
      >
        {languageState.translation.SIDEBAR.ADD_FEEDBACK}
      </ModalHeader>
      <ModalBody
        className="mt-0 pt-0 "
        style={{
          overflow: 'hidden',
        }}
      >
        <div className=' d-flex align-items-center gap-1  mb-3 c-pointer'>
          {userState && userState?.profileImage
            ? (
              <span className="avatar avatar-sm rounded-circle">
                <img alt="..." src={`${IMG_PREFIX}${userState.userInfo.profileImage}`} />
              </span>
            )
            : (
              <Avatar
                firstName={userState.userInfo && userState.userInfo.firstName}
                lastName={userState.userInfo && userState.userInfo.lastName}
                name={`${userState.userInfo && userState.userInfo.firstName} ${userState.userInfo && userState.userInfo.lastName}`}
                forSidebar
                className="rounded-circle custom-avatar "
              />
            )}
          <div className='d-flex flex-column user-information'>
            <h5 className='mb-0'>{`${userState.userInfo && userState.userInfo.firstName} ${userState.userInfo && userState.userInfo.lastName}.`}</h5>
            <h6 className='mb-0'>{userState.userInfo && languageState.translation.STEPPER.FIRST_STEP_OPTIONS[userState.userInfo.profession]}</h6>
          </div>
        </div>
        <Formik
          initialValues={{
            message: '',
            screenshots: []
          }}
          validationSchema={schema(validationSchemaMessages)}
          onSubmit={async (values) => {
            addFeedbackHandler(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <div>
              <Label className="d-block mt-1">
                {languageState.translation.COMMON.COMMENTORFEEDBACK}
              </Label>
              <Input
                type="textarea"
                name="message"
                onChange={handleChange}
              />
              <small className="d-block m-1 text-red">
                {errors.message && touched.message && errors.message}
              </small>
              <div className="file-upload-container">
                <Input
                  type="file"
                  name="screenshots"
                  multiple
                  accept="image/*"
                  id="file-upload"
                  onChange={(event) => {
                    setFieldValue("screenshots", Array.from(event.currentTarget.files));
                  }}
                />
                <label htmlFor="file-upload" className="custom-file-upload">
                  <i className="fa fa-upload"></i> {languageState.translation.COMMON.ATTACH_SCREENSHOT}
                </label>
              </div>

              <div className="file-preview">
                {values.screenshots &&
                  values.screenshots.map((file, index) => (
                    <div key={index} className="file-item">
                      <img
                        src={URL.createObjectURL(file)}
                        alt="logo Preview"
                        className="file-preview-image"
                      />
                    </div>
                  ))}
              </div>

              <button
                className="btn bg-primary mt-2 ml-auto d-block"
                type="submit"
                onClick={() => handleSubmit()}
                disabled={isSubmitting}
              >
                {languageState.translation.COMMON.BUTTONS.SUBMIT}
              </button>
            </div>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  )
}

export default AddFeedbackModal