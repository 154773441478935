import { useContext, useEffect, useState } from 'react';
import { AppContext } from 'store/app.context';
import "./sales-pipeline.css";
import { useSalesPipelineService } from 'services/sales-pipeline/sales-pipeline.service';
import Column from './Column';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Nav, NavItem, NavLink, Button } from 'reactstrap';
import classnames from 'classnames';
import ListApplications from './ListApplications';
import APPLICATIONS_STATE from 'store/applications/applications.state';
import CardsHeaderFocus from 'components/cards/card-header-focus';
import { ModalAddLead } from 'components/ModalAddLead/addLeadModal';
import { useLeadsService } from 'services/leads/leads.service';
import LEADS_STATE from 'store/leads/leads.state';

const SalesPipeline = () => {
    const { languageState, applicationState, leadsState, dispatchApplicationState, dispatchLeadsState } = useContext(AppContext);
    const [view, setView] = useState('kanban');
    const [currentActiveTab, setCurrentActiveTab] = useState('1');
    const [isFilterActive, setIsFilterActive] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const { getAllApplications } = useSalesPipelineService();
    const { getLeads, updateLead } = useLeadsService();

    const columns = ['APPLIED', 'INTRODUCED', 'CONNECTED', 'INTERVIEW_PLANNED', 'SELECTED', 'REJECTED'];


    useEffect(() => {
        const fetchApplications = async () => {
            const result = await getAllApplications()

            dispatchApplicationState({
                type: APPLICATIONS_STATE.types.SET_APPLICATIONS,
                applications: result,
            });
        };

        fetchApplications();
    }, []);
    useEffect(() => {

        const fetchLeads = async () => {
            await getLeads();
        }
        fetchLeads();
    }, [leadsState.leads.length])

    const resetFilters = () => {

        setIsFilterActive(false);
    };

    const handleTabClick = (tab, view) => {
        setCurrentActiveTab(tab);
        setView(view);
    };

    const calculateTotalRevenue = (applications) => {
        return applications?.reduce((sum, application) => sum + application.estimatedRevenue, 0);
    };
    const totalPotentialRevenue =
        calculateTotalRevenue(
            applicationState.applications.filter(
                (application) => application.status !== "SELECTED"
            )
        ) +
        calculateTotalRevenue(
            leadsState.leads.filter(
                (lead) => lead.stage !== "SELECTED"
            )
        );
    const totalPlannedRevenue =
        calculateTotalRevenue(
            applicationState.applications.filter(
                (application) => application.status === "SELECTED"
            )
        ) + calculateTotalRevenue(
            leadsState.leads.filter(
                (lead) => lead.stage === "SELECTED"
            )
        );
        
    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('nl-BE', {
            style: 'currency',
            currency: 'EUR'
        }).format(amount);
    };
    const handleDragEnd = async (leadId, newStage) => {
        const lead = leadsState.leads.find((lead) => lead.id === leadId);
        const newLead = { ...lead, stage: newStage }
        
        dispatchLeadsState({
            type: LEADS_STATE.types.UPDATE_LEAD,
            lead: { ...lead, stage: newStage },
        });
        await updateLead(newLead, leadId);

    };
    return (
        <>
            <CardsHeaderFocus
                name={languageState.translation.SIDEBAR.PENDING_APPLICATIONS}
                breadcrumb
                className='job-breadcrumb'
                published="" type={undefined} subName={undefined}
                breadCrumbLinks={undefined}
            />
            <div className="sales-body">
                <div className='sales-pipeline-header'>
                    <Nav className="border-none" tabs>
                        <NavItem className="c-pointer">
                            <NavLink
                                className={classnames({ active: currentActiveTab === '1' })}
                                onClick={() => handleTabClick('1', 'kanban')}
                            >
                                <i className="fa fa-columns mr-2" />{languageState.translation.CONNECT.CARDS}
                            </NavLink>
                        </NavItem>
                        <NavItem className="c-pointer">
                            <NavLink
                                className={classnames({ active: currentActiveTab === '2' })}
                                onClick={() => handleTabClick('2', 'list')}
                            >
                                <i className="fa fa-list mr-2" />{languageState.translation.CONNECT.LIST}
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <div className='revenue'>
                        <p className='font-weight-bold'>{languageState.translation.JOBBOARD.TOTAL_POTENTIAL_REVENUE}: {totalPotentialRevenue ? `${formatCurrency(totalPotentialRevenue)}` : `${formatCurrency(0)}`}</p>
                        <p className='font-weight-bold'>{languageState.translation.JOBBOARD.TOTAL_PLANNED_REVENUE}: {totalPlannedRevenue ? `${formatCurrency(totalPlannedRevenue)}` : `${formatCurrency(0)}`}</p>
                    </div>
                    {isFilterActive && (
                        <Button color="secondary" onClick={resetFilters} className="reset-filter-btn">
                            Reset Filter
                        </Button>
                    )}
                </div>
                <Button
                    color="danger"
                    className="m-2 ml-auto shadow-none d-flex gap-1 align-items-center add-lead"
                    onClick={() => setModalShow(true)}>
                    {/* <i className="fas fa-plus"></i> */}
                    {languageState.translation.COMMON.ADD_LEAD}
                </Button>
                <div className='content'>
                    {view === 'kanban' ? (
                        <div className="kanban-container">
                            <DndProvider backend={HTML5Backend}>
                                <div className="kanban-board">
                                    {columns.map((status) => (
                                        <Column
                                            key={status}
                                            status={status}
                                            applications={applicationState.applications.filter(application =>
                                                application.status === status
                                            )}
                                            leads={leadsState.leads.filter((lead) => lead.stage === status)}
                                            onDragEnd={handleDragEnd}
                                        />
                                    ))}
                                </div>
                            </DndProvider>
                        </div>
                    ) : (
                        <div className='list-view-container'>
                            <ListApplications applications={applicationState.applications} leads={leadsState.leads} />
                        </div>
                    )}
                </div>
            </div>
            <ModalAddLead
                modalShow={modalShow}
                closeModal={() => setModalShow(false)}
            />
        </>
    );
};

export default SalesPipeline;
