import { IMG_PREFIX } from 'config/core';
import { motion } from 'framer-motion';
import APP_ROUTES from 'navigation/app.routes';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, CardBody, CardImg, CardText, CardTitle, DropdownItem, DropdownMenu, DropdownToggle, Tooltip, UncontrolledDropdown } from 'reactstrap';
import useConnectService from 'services/connect/connect.service';
import useInvitationService from 'services/invitations/invitation.service';
import { AppContext } from 'store/app.context';
import { statusesColors } from 'components/modalStepper/onboarding.constant';
import _ from 'lodash';
import './user-card.scss'

const UserCard = ({ user, openModalAddNote, mode, openModalDelete, openModalAddUserGroup, openModalAddUserTags, module }) => {
  const { languageState, userState } = useContext(AppContext);
  const { addToFavorite, removeFromfavorite, getFavorites, getConnectedUsers } = useConnectService();
  const history = useHistory();
  const { sendInvitation } = useInvitationService();
  const { pathname } = useLocation();
  const [tooltipIndex, setTooltipIndex] = useState(null)
  const [userSkills, setUserSkills] = useState([])
  const defaultComission=1.1;

  useEffect(() => {
    if (user) {
      setUserSkills(
        [
          ...(user.skills || []),
          ...((user.otherSkills || []).map((el) => {
            return { id: el, key: el }
          }))
        ]
      )
    }
  }, [user])

  const handleAddFavorite = async () => {
    const result = await addToFavorite(user.id);
    if (result && result.status === 200) {
      toast.success(languageState.translation.ALERT.ADD_USER_FAVORITES, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      getConnectedUsers();
    }
  };
  const handleSendInvitation = async () => {
    const result = await sendInvitation(user.id);
    if (result && result.status === 201) {
      toast.success(languageState.translation.ALERT.SUCCESS_INVITATION, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    }
  };

  const handleRemoveFavorite = async () => {
    const result = await removeFromfavorite(user.id);
    if (result && result.status === 200) {
      toast.success(languageState.translation.ALERT.REMOVE_USER_FAVORITES, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      getFavorites();
    }
  };

  const userAvailabilityColor = () => {
    if (user.statusAviability && statusesColors[user.statusAviability]) {
      return statusesColors[user.statusAviability];
    }
    return null
    // else if (user?.workingTime === 'FULL_TIME') {
    //   return '#2DCE89';
    // } else if (user?.workingTime === 'PART_TIME') {
    //   return '#F2994A';
    // }
  };

  const getUserWorkingTime = () => {
    if (user.statusAviability && languageState.translation.COMMON[user.statusAviability]) {
      return languageState.translation.COMMON[user.statusAviability]
    }
    return null
    // if (user.workingTime === 'FULL_TIME') {
    //   return languageState.translation.CONNECT.FULL_TIME_AVAILABLE;
    // } else {
    //   return languageState.translation.CONNECT.PART_TIME_AVAILABLE;
    // }
  };

  const rateIndication = () => {
    const level = user.experienceYears;
    const key = user.paymentType === 'DAILY_BASED'
      ? "PER_DAY"
      : user.paymentType === 'HOURLY_BASED'
        ? "PER_HOUR"
        : user.paymentType === 'WEEKLY_BASED'
          ? "PER_WEEK"
          : "PER_DAY"; 
  
    const calculatedAmount = user.amount 
      ? Math.round(parseFloat(user.amount) * parseFloat(defaultComission)) 
      : 0; // Default to 0 if user.amount is missing
  
    return ['HOURLY_BASED', 'DAILY_BASED', 'WEEKLY_BASED'].includes(user.paymentType)
      ? `${calculatedAmount} euro ${languageState.translation.COMMON[key]}`
      : (level === 0 ? languageState.translation.COMMON.NOT_INDICATED : '');
  };

  return (
    <motion.div
      layout
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      key={user.id}
      className="user-card card h-100"
    >
      <CardBody key={user.id} className="h-100 d-flex align-items-start flex-column p-0">
        <div className='w-100' style={{ position: 'relative' }}>
          <div className="user-image">
            <CardImg
              alt="..."
              src={user.profileImage ? `${IMG_PREFIX}${user.profileImage}` : 'assets/img/man.png'}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = require('assets/img/man.png').default;
              }}
              className="user-image object-fit-cover"
              top
            />
          </div>
          {
            getUserWorkingTime() &&
            <div className='availability-text' >
              <CardText
                style={{
                  backgroundColor: userAvailabilityColor(),
                }}
              >
                {getUserWorkingTime()}
              </CardText>

            </div>
          }
          {userState?.userInfo?.kind === 'COMPANY' && (
            <div className='pill-hourly-daily-rate' >
              <p>{rateIndication()}</p>
            </div>)
          }
        </div>
        {mode === 'ONBOARDING'
          ? null
          : <UncontrolledDropdown className="icon-network" nav>
            <DropdownToggle className="nav-link pr-0" color="" tag="a">
              <div className="icon icon-shape bg-gradient-blue text-white rounded c-pointer shadow ">
                <i className="ni ni-settings-gear-65" />
              </div>
            </DropdownToggle>
            <DropdownMenu right>
              {!user?.isConnected && mode != 'CONNECTED' && <DropdownItem className="noti-title hover_title" header tag="div">
                <h5
                  className="text-overflow m-0"
                  onClick={() => handleSendInvitation()}
                >
                  {languageState.translation.CONNECT.CONNECT_WITH_USER}
                </h5>
              </DropdownItem>}
              <DropdownItem className="noti-title hover_title" header tag="div">
                <h5
                  className="text-overflow m-0 p-0"
                  role="button"
                  onClick={() => {
                    openModalAddNote();
                  }}
                >
                  {languageState.translation.CONNECT.ADD_PER_NOTE}
                </h5>
              </DropdownItem>
              {mode && mode == 'CONNECTED' && (
                <DropdownItem className="noti-title hover_title" header tag="div">
                  <h5
                    className="text-overflow m-0"
                    onClick={() => {
                      openModalDelete();
                    }}
                  >
                    {languageState.translation.CONNECT.REMOVE_FROM_NETWORK}{' '}
                  </h5>
                </DropdownItem>
              )}
              <DropdownItem className="noti-title hover_title" header tag="div">
                <h5
                  className="text-overflow m-0"
                  onClick={() => openModalAddUserGroup()}
                >
                  {languageState.translation.CONNECT.ADD_TO_GROUP}
                </h5>
              </DropdownItem>
              <DropdownItem className="noti-title hover_title" header tag="div">
                <h5
                  className="text-overflow m-0"
                  onClick={() => openModalAddUserTags()}
                >
                  {languageState.translation.CONNECT.TAG_USER}
                </h5>
              </DropdownItem>
              {!module &&
                module !== 'favorites' &&
                mode &&
                mode == 'CONNECTED' &&
                !user.favorite && (
                  <DropdownItem className="noti-title hover_title" header tag="div">
                    <h5
                      className="text-overflow m-0 w-100"
                      onClick={handleAddFavorite}
                    >
                      {languageState.translation.CONNECT.ADD_TO_FAVORITES}
                    </h5>
                  </DropdownItem>
                )}
              {module && module == 'favorites' && (
                <DropdownItem className="noti-title hover_title" header tag="div">
                  <h5
                    className="text-overflow m-0 w-100"
                    onClick={handleRemoveFavorite}
                  >
                    {languageState.translation.CONNECT.REMOVE_FROM_FAVORITES}
                  </h5>
                </DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>}
        <div className="mt-2 p-2">
          <CardTitle className="h2 mb-0 h-40">
            {
              user.firstName && user.lastName
                ? mode === "ONBOARDING"
                  ? `${_.upperFirst(user.firstName)} ${_.toUpper(user.lastName[0].charAt(0))}.`
                  : `${_.upperFirst(user.firstName)} ${_.upperFirst(user.lastName.charAt(0))}.`
                : "- - -"
            }
          </CardTitle>
          {
            user.region
              ? <small className="text-muted d-flex align-items-center" style={{ gap: 5 }}>
                <i className="ni ni-world" />
                {user.city
                  ? <label>{`${user.city},`}</label>
                  : null
                }
                <label>{user.region}</label>
              </small>
              : null
          }

          <CardText className="mt-3 max-content-text three-lines" dangerouslySetInnerHTML={{ __html: user.aboutMe || "- - -" }}></CardText>
          <div className="d-flex flex-wrap max-content-skills">
            {userSkills && userSkills.length
              ? <>
                {
                  userSkills.slice(0, 2).map((skill, index) => {
                    return <span className="h6 btn btn-sm tag-skill max-content-height" key={skill.id}>
                      {skill.key.length > 15
                        ?
                        <>
                          <div id={`skill-${index}-${user.id}`}> {`${_.slice(skill.key, 0, 15).join("")}...`} </div>
                          <Tooltip
                            placement="bottom"
                            isOpen={tooltipIndex === skill.id}
                            target={`skill-${index}-${user.id}`}
                            toggle={() => {
                              setTooltipIndex(tooltipIndex === skill.id ? null : skill.id)
                            }}
                          >
                            {skill.key}
                          </Tooltip>
                        </>
                        : skill.key}
                    </span>
                  })
                }
                {
                  userSkills.length > 2 && (
                    <span className="h6 btn btn-sm tag-skill max-content-height">
                      {`+${userSkills.length - 2} ${languageState.translation.COMMON.OTHERS}`}
                    </span>
                  )
                }
              </>
              : null}
          </div>
        </div>
        {
          mode === "ONBOARDING"
            ? null
            : <Button
              className="mt-auto px-0 btn-lg btn-block btn-viewProfile w-100 text-white mt-auto"
              color="link"
              disabled={mode === "ONBOARDING"}
              onClick={(e) =>
                history.push({
                  pathname:
                    '/private' + APP_ROUTES.CONNECT.PROFILE_DETAILS + '/' + user.id,
                  state: { previousPath: pathname },
                })
              }
            >
              {languageState.translation.CONNECT.VIEW_PROFILE}
            </Button>
        }

      </CardBody>
    </motion.div>
  );
};

export default UserCard;
