import { useContext, useEffect, useMemo, useState } from "react";
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Table } from "reactstrap";
import { AppContext } from "store/app.context";
import moment from 'moment';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ListApplications = ({ applications, leads }) => {
  const { languageState } = useContext(AppContext);
  const [deadLineApplicationReverseSort, setDeadLineApplicationReverseSort] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [priorityDropdownOpen, setPriorityDropdownOpen] = useState(false);
  const [companyDropdownOpen, setCompanyDropdownOpen] = useState(false);
  const history = useHistory()
  const [selectedFilters, setSelectedFilters] = useState({
    company: '',
    status: '',
    priority: ''
  });

  const getStatusStyle = (status) => {
    switch (status) {
      case languageState.translation.COMMON.STATUS_LIST.APPLIED:
        return { color: '#fb6340', backgroundColor: 'rgba(251,99,64,.12)!important' };
      case languageState.translation.COMMON.STATUS_LIST.INTRODUCED:
        return { color: '#fb6340', backgroundColor: 'rgba(251,99,64,.12)!important' };
      case languageState.translation.COMMON.STATUS_LIST.CONNECTED:
        return { color: '#fb6340', backgroundColor: 'rgba(251,99,64,.12)!important' };
      case languageState.translation.COMMON.STATUS_LIST.INTERVIEW_PLANNED:
        return { color: '#fb6340', backgroundColor: 'rgba(251,99,64,.12)!important' };
      case languageState.translation.COMMON.STATUS_LIST.WON:
        return { color: '#1aae6f', backgroundColor: 'rgba(245,54,92,.12)!important' };
      case languageState.translation.COMMON.STATUS_LIST.LOST:
        return { color: '#f5365c', backgroundColor: 'rgba(245,54,92,.12)!important' };
      default:
        return { color: '', backgroundColor: '#F8F9FA' };
    }
  };
  useEffect(()=> {
  }, [languageState])

  const combinedData = useMemo(() => [
    ...applications.map(app => ({
      ...app,
      type: 'application',
      title: app.job.functionTitle,
      company: app.job.company,
      status: app.status === "SELECTED" 
        ? languageState.translation.COMMON.STATUS_LIST.WON 
        : app.status === "REJECTED" 
        ? languageState.translation.COMMON.STATUS_LIST.LOST 
        : languageState.translation.COMMON.STATUS_LIST[app.status],
      createdAt: app.job.createdAt,
      applicationDate: app.createdAt,
      details: `/private/jobs/job-details/${app.job.id}`
    })),
    ...leads.map(lead => ({
      ...lead,
      type: 'lead',
      company: lead.company,
      status: lead.stage === "SELECTED" 
        ? languageState.translation.COMMON.STATUS_LIST.WON 
        : lead.stage === "REJECTED" 
        ? languageState.translation.COMMON.STATUS_LIST.LOST 
        : languageState.translation.COMMON.STATUS_LIST[lead.stage],
      createdAt: lead.createdAt,
      details: `/private/leads/lead-details/${lead.id}`
    }))
  ], [applications, leads, languageState]);

  const [sortedApplications, setSortedApplications] = useState(combinedData);
  useEffect(() => {
    setSortedApplications(combinedData);
  }, [combinedData]);
  useEffect(() => {
    filterApplications();
  }, [selectedFilters, searchTerm, deadLineApplicationReverseSort]);

  const uniqueCompanies = [...new Set(combinedData.map(app => app.company))];
  console.log(uniqueCompanies)
  const uniqueStatuses = [...new Set(combinedData.map(app => app.status))];
  //const uniquePriorities = [...new Set(applications.map(app => app.job.priority))];

  const filterApplications = () => {
    let filteredApplications = combinedData;

    if (selectedFilters.company) {
      filteredApplications = filteredApplications.filter(
        app => app.company === selectedFilters.company
      );
    }
    if (selectedFilters.status) {
      filteredApplications = filteredApplications.filter(
        app => app.status === selectedFilters.status
      );
    }
    if (selectedFilters.priority) {
      filteredApplications = filteredApplications.filter(
        app => app.job.priority === selectedFilters.priority
      );
    }
    if (searchTerm) {
      filteredApplications = filteredApplications.filter(application =>
        application && application.title && application.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    setSortedApplications(filteredApplications);
  };

  const handleFilterSelect = (type, value) => {
    setSelectedFilters(prevFilters => ({ ...prevFilters, [type]: value }));
  };

  const resetFilters = () => {
    setSelectedFilters({
      company: '',
      status: '',
      priority: ''
    });
    setSearchTerm("");
  };

  const isFilterActive = () => {
    return selectedFilters.company || selectedFilters.status || selectedFilters.priority;
  };

  return (
    <div className="list-view h-200">
      <div>


        <div className="w-[5rem] d-flex align-items-center justify-content-end">
          {/* {isFilterActive() && (
            <p color="secondary" onClick={resetFilters} className="font-weight-600 cursor-pointer mr-2">
              Reset Filters
            </p>
          )}
          {uniqueCompanies.length &&
          <Dropdown isOpen={companyDropdownOpen} toggle={() => setCompanyDropdownOpen(!companyDropdownOpen)}>
            <DropdownToggle caret color="">
              {languageState.translation.COMMON.FILTER_BY_COMPANY}
            </DropdownToggle>
            <DropdownMenu>
              {uniqueCompanies.map(company => (
                <DropdownItem
                  key={company}
                  onClick={() => handleFilterSelect('company', company)}
                >
                  {company}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
          }
          {
          uniqueStatuses.length &&
          <Dropdown isOpen={statusDropdownOpen} toggle={() => setStatusDropdownOpen(!statusDropdownOpen)}>
            <DropdownToggle caret color="">
            {languageState.translation.COMMON.FILTER_BY_STATUS}
            </DropdownToggle>
            <DropdownMenu>
              {uniqueStatuses.map(status => (
                <DropdownItem
                key={status}
                onClick={() => handleFilterSelect('status', status)}
                >
                  {status}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
            } */}
          <Input
            type="text"
            placeholder={languageState.translation.COMMON.SEARCH_BY_JOB}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ width: '200px', margin: '10px' }}
          />
        </div>

        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
         <tr>
              <th scope="col">{languageState.translation.CONNECT.JOB_TITLE}</th>

              <th scope="col">{languageState.translation.COMMON.STATUS}</th>
              {/* <th scope="col">{languageState.translation.COMMON.COMPANY}</th> */}
              <th scope="col" onClick={() => setDeadLineApplicationReverseSort(prev => !prev)}>
                {languageState.translation.COMMON.PUBLICATION_DATE}
              </th>
              <th scope="col" onClick={() => setDeadLineApplicationReverseSort(prev => !prev)}>
                {languageState.translation.JOBBOARD.APPLICATION_DATE}
              </th>

            </tr> 
          </thead>
          <tbody>
            {sortedApplications.map(item => (
              <tr key={item.id} onClick={() => {
                sessionStorage.setItem("previousUrl", "pendingApplications");
                history.push(item.details)
              }} className="table-row">
                <td>
                  <span  >{item.title}</span>
                </td>
                <td>
                  <p style={getStatusStyle(item.status)} className="text-left rounded-lg font-weight-400">
                    {item.status}
                  </p>
                </td>
                {/* <td>{item.company}</td> */}
                <td>
                  {item.job?.createdAt
                    ? moment(item.job.createdAt).format('DD-MM-YYYY')
                    : moment(item.createdAt).format('DD-MM-YYYY')}
                </td>
                <td>
                  {item.applicationDate
                    ? moment(item.applicateDate).format('DD-MM-YYYY')
                    : "N/A"}
                </td>
                <td>
                  <div
                    color="primary"
                    onClick={() => history.push(item.details)}
                  >
                    <i className="fas fa-chevron-right"></i>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default ListApplications;
