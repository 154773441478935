import { useContext } from 'react';
import useChatHttpClient from 'services/chat-http-client';
import { AppContext } from 'store/app.context';
import TYPES from 'store/chat/types';

const useChatService = () => {
  const { dispatchChatState } = useContext(AppContext);
  const { chatClient } = useChatHttpClient();
  const getRooms = async (page = 1, perPage = 20) => {
    const result = await chatClient.get(
      `/rooms?order=DESC`
    );
    if (result && result.status === 200) {
      dispatchChatState({
        type: TYPES.GET_ROOMS,
        rooms: result?.data?.rooms || [],
        hasMoreConversations: result?.data?.has_next || false,
        page,
    });
    }
  };

  const getMessagesByRoom = async (roomId, page = 1, perPage = 20) => {
    const result = await chatClient.get(
      `/rooms/${roomId}?page=${page}&pageSize=${perPage}&order=DESC`
    );
    if (result && result.status === 200) {
      const payload = {
        type: TYPES.GET_MESSAGES,
        messages: result?.data?.messages || [],
        hasMoreMessages: result?.data?.has_next || false,
      };
      dispatchChatState(payload);
    }
  };

  const getOnlineUsers = async () => {
    const result = await chatClient.get(`/users/online`);
    if (result && result.status === 200) {
      const payload = {
        type: TYPES.GET_ONLINE_USERS,
        onlineUsers:
          result?.data && Array.isArray(result?.data)
            ? result?.data?.map((user) => user?.id)
            : [],
      };
      dispatchChatState(payload);
    }
  };

  const getUsers = async (currentUserId) => {
    const result = await chatClient.get(`/users`);
    if (result && result.status === 200) {
      const payload = {
        type: TYPES.GET_USERS,
        users:
          result?.data && Array.isArray(result?.data)
            ? result?.data?.filter((user) => user?.id !== currentUserId)
            : [],
      };
      dispatchChatState(payload);
    }
  };

  const createConversation = async (conversation, socketId) => {
    const { data } = await chatClient.post('/rooms', {
      ...conversation,
      socketId,
    });
    return { code: data?.header?.code, conversation: data?.result };
  };

  const uploadMessageFile = (file) => {
    const formData = new FormData();
    formData.append('files', file);
    return chatClient.post('/attachments/files/upload', formData);
  };

  const getMessageFile = async (filePath) => {
    const result = await chatClient.get(filePath, { responseType: 'blob' });
    return result;
  };

  return {
    getRooms,
    getOnlineUsers,
    getUsers,
    createConversation,
    getMessagesByRoom,
    uploadMessageFile,
    getMessageFile,
  };
};

export default useChatService;
