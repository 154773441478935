import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import CardsHeaderNetwork from 'components/cards/card-header-network';
import DeleteModal from 'components/layout/DeleteModal/DeleteModal';
import ModalAddToGroup from 'components/ModalAddToGroup/ModalAddToGroup';
import ModalAddUserTags from 'components/modalAddUserTags/ModalAddUserTags';
import ModalNotes from 'components/modalNotes/ModalNotes';
import UserCard from 'components/user-card/user-card';
import { Button, ButtonGroup, Card, CardBody, CardHeader, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import useConnectService from 'services/connect/connect.service';
import { AppContext } from 'store/app.context';
import { getQueryParamsFromObject } from 'utils';
import Select from 'react-select';
import _, { isEqual } from 'lodash';
import useJobsService from 'services/jobs/jobs.service';
import { statuses } from 'components/modalStepper/onboarding.constant';
import { professions } from 'components/modalStepper/onboarding.constant';
import useSettingsService from 'services/settings/settings.service';


const YourNetwork = ({ type }) => {
  const timer = useRef(null);
  const { getAllUsers, getConnectedUsers, getAllNetworkCountries, getAssignedSkills } = useConnectService();
  const { getCategories } = useJobsService();
  const { languageState, connectState, userState } = useContext(AppContext);
  const [users, setUsers] = useState(null);
  const [options, setOptions] = useState([]);
  const [skills, setSkills] = useState([])
  const [minSlider, setMinSlider] = useState(0);
  const [maxSlider, setMaxSlider] = useState(100);
  const [modalShow, setModalShow] = useState(false);
  const [modalAddGroupShow, setModalAddGroupShow] = useState(false);
  const [tagModalShow, setTagModalShow] = useState(false);
  const [modalDeleteShow, setModalDeleteShow] = useState(false);
  const [paginationList, setPagination] = useState([]);
  const [userid, setUserId] = useState(null);
  const [mode, setMode] = useState(type);
  const [countries, setCountyList] = useState([]);
  const [numberOfUsers, setNumberOfUsers] = useState(0);
  const [noDataFound, setNotDataFound] = useState(false);
  const [nameToSearch, setNameToSearch] = useState('');
  const [experienceLevelString, setExperienceLevelString] = useState([]);
  const [filters, setFilters] = useState({
    dailyRateMin: 0,
    dailyRateMax: 100,
    profession: [],
    experienceLevel: [],
    positions: [],
    country:
      process.env.REACT_APP_ENV !== 'qa' &&
        countries &&
        countries.includes('Belgium')
        ? ['Belgium']
        : [],
    categories: ['All'],
    name: '',
    statusAviability: '',
    skills: []
  });
  const filterCached = useMemo(() => filters, []);

  useEffect(() => {
    getAssignedSkills();
  }, [])

  useEffect(() => {
    if (connectState && connectState.assignedSkills) {
      setOptions(connectState.assignedSkills.map(skill => ({
        value: skill.id,
        label: skill.key
      })));
    }
  }, [connectState])

  useEffect(() => {
    getAllNetworkCountries();
    getCategories();
    setFilters({
      ...filters,
      categories: ['All'],
    });
  }, []);

  useEffect(() => {
    if (
      connectState &&
      connectState.countries &&
      connectState.countries.length
    ) {
      setCountyList(connectState.countries);
    }
  }, [connectState]);

  useEffect(() => {
    if (mode) {
      const reqData = getQueryParamsFromObject({ ...filters });

      if (mode === 'CONNECTED') {
        getConnectedUsers(reqData);
      } else {
        getAllUsers(reqData);
      }
    }
  }, [mode, filters]);

  const getMoreUsers = (page) => {
    if (page !== connectState.config.currentPage) {
      const reqData = getQueryParamsFromObject({ ...filters, page });
      if (mode) {
        if (mode === 'CONNECTED') {
          getConnectedUsers(reqData);
        } else {
          getAllUsers(reqData);
        }
        window.scrollTo(0, 0);
      }
    }
  };
  const nextPage = () => {
    if (connectState.config.currentPage < connectState.config.totalPages) {
      const page = connectState.config.currentPage + 1;
      const reqData = getQueryParamsFromObject({ ...filters, page });
      if (mode) {
        if (mode === 'CONNECTED') {
          getConnectedUsers(reqData);
        } else {
          getAllUsers(reqData);
        }
        window.scrollTo(0, 0);
      }
    }
  };
  const prevPage = () => {
    if (connectState.config?.currentPage > 1) {
      const page = connectState.config.currentPage - 1;
      const reqData = getQueryParamsFromObject({ ...filters, page });
      if (mode) {
        if (mode === 'CONNECTED') {
          getConnectedUsers(reqData);
        } else {
          getAllUsers(reqData);
        }
        window.scrollTo(0, 0);
      }
    }
  };
  const hasNext = () => {
    if (connectState.config && connectState.config.currentPage) {
      if (connectState.config.currentPage < connectState.config.totalPages) {
        return false;
      }
    }
    return true;
  };
  const hasPrev = () => {
    if (connectState.config && connectState.config.currentPage) {
      if (connectState.config?.currentPage > 1) {
        return false;
      }
      return true;
    }
  };

  useEffect(() => {
    if (mode === 'CONNECTED') {
      if (connectState && connectState.connectedUsers) {
        const users = connectState.connectedUsers.filter(
          (user) => user.kind !== 'COMPANY'
        );
        setUsers(users);
        setNumberOfUsers(connectState.config.totalItems);
        getPaginate();
      }
    } else {
      if (connectState && connectState.allUsers) {
        const users = connectState.allUsers.filter(
          (user) => user.kind !== 'COMPANY'
        );
        setNumberOfUsers(connectState.config.totalItems);

        setUsers(users);
        getPaginate();
      }
    }
  }, [connectState]);

  useEffect(() => {
    setMode(type);
  }, [type]);

  useEffect(() => {
    if (!isEqual(filterCached, filters)) {
      if (mode === 'CONNECTED') {
        if (connectState && connectState.connectedUsers?.length === 0) {
          setNotDataFound(true);
        } else {
          setNotDataFound(false);
        }
      } else {
        if (connectState && connectState.allUsers?.length === 0) {
          setNotDataFound(true);
        } else {
          setNotDataFound(false);
        }
      }
    }
  }, [filters, connectState]);

  const changeCategoryHandler = (category) => {
    const oldCategories = filters.categories;
    let newCategories = [];
    if (oldCategories.includes(category)) {
      newCategories = oldCategories.filter((item) => item !== category);
    } else {
      newCategories = [...oldCategories, category];
    }
    setFilters({
      ...filters,
      categories: newCategories,
    });
  };

  const changeExperienceLevelsFilter = (type) => {
    const oldExperienceLevels = experienceLevelString;
    let newExperienceLevels = [];
    if (oldExperienceLevels.includes(type)) {
      newExperienceLevels = oldExperienceLevels.filter((item) => item !== type);
    } else {
      newExperienceLevels = [...oldExperienceLevels, type];
    }

    setExperienceLevelString(newExperienceLevels);

    setFilters({
      ...filters,
      experienceLevel: newExperienceLevels,
    });
  };

  const onSliderChange = (e) => {
    setMinSlider(e[0]);
    setMaxSlider(e[1]);
  };

  const getPaginate = () => {
    const listpag = [];
    if (connectState.config && connectState.config.totalPages) {
      if (connectState.config?.currentPage > 1) {
        listpag.push(connectState.config?.currentPage - 1);
      }
      listpag.push(connectState.config?.currentPage);
      if (connectState.config?.currentPage < connectState.config.totalPages) {
        listpag.push(connectState.config?.currentPage + 1);
      }
      setPagination(listpag);
    }
  };


  const handleCountryChange = (event, country) => {
    if (!country) return;

    if (event.target.checked) {
      setFilters({
        ...filters,
        country: [...filters?.country, country],
      });
    } else {
      const filteredCountry = filters?.country?.filter(
        (countryFilter) => countryFilter !== country
      );
      setFilters({
        ...filters,
        country: filteredCountry,
      });
    }
  };

  const openModalAddUserTags = (user) => {
    setUserId(user.id);
    setTagModalShow(true);
  };

  const searchByName = () => {
    setFilters((previousFiler) => {
      return {
        ...previousFiler,
        name: nameToSearch.toLowerCase().trim(),
      };
    });
  };

  const resetFilter = () => {
    setNameToSearch('')
    setExperienceLevelString([])
    setSkills([])
    setFilters({
      dailyRateMin: 0,
      dailyRateMax: 100,
      profession: [],
      experienceLevel: [],
      positions: [],
      country:
        process.env.REACT_APP_ENV !== 'qa' &&
          countries &&
          countries.includes('Belgium')
          ? ['Belgium']
          : [],
      categories: ['All'],
      name: '',
      statusAviability: '',
      skills: []
    });
  }

  return (
    <div className='network-list'>
      <CardsHeaderNetwork
        type={mode}
        numberOfUsers={numberOfUsers}
        onBtnClick={() => setMode(mode === 'CONNECTED' ? 'ALL' : 'CONNECTED')}
      />
      <Container className="mt-3" fluid>
        <Row>
          <Col xl={3} lg={3} md={4} sm={12}>
            <Card className='card-filter'>
              <CardHeader>
                <h3>{languageState.translation.CONNECT.SEARCH_BY_NAME}</h3>
              </CardHeader>
              <CardBody>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i
                        className="fa fa-search"
                        aria-hidden="true"
                        style={{ color: '#675cff', fontSize: 18 }}
                      ></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    value={nameToSearch}
                    onChange={(e) => {
                      setNameToSearch(e.target.value);
                    }}
                    style={{ backgroundColor: 'white', color: 'Black' }}
                    placeholder={languageState.translation.CONNECT.ENTER_NAME}
                    onKeyUp={() => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(searchByName, 500);
                    }}
                  ></Input>
                </InputGroup>
              </CardBody>
            </Card>
            {userState.userInfo.kind === "ADMIN" || userState.userInfo.kind === "RECRUITER" ?
            <Card>
              <CardHeader>
                <h3 className="mb-0">
                  {languageState.translation.CONNECT.SEARCH_BY_SKILLS}
                </h3>
              </CardHeader>
              <CardBody>
                <Select
                  options={options}
                  isSearchable
                  isMulti
                  isClearable
                  value={skills}
                  placeholder={languageState.translation.COMMON.SELECT_SKILLS}
                  onChange={(e) => {
                    setSkills(e)
                    setFilters({ ...filters, skills: e && e.map(item => item.value) })
                  }}
                />
              </CardBody>
            </Card> : <></> 
}
            <Card className='card-filter'>
              <CardHeader>
                <h3 className="mb-0">
                  {languageState.translation.COMMON.AVAILIBILITY}
                </h3>
              </CardHeader>
              <CardBody
                style={{
                  maxHeight: '400px',
                  overflowY: 'auto',
                }}
              >
                {
                  statuses.map((status, index) => {
                    return <Col key={index} md="12">
                      <div className="custom-control custom-checkbox mb-3">
                        <input
                          className="custom-control-input"
                          id={status}
                          type="checkbox"
                          checked={filters.statusAviability === status}
                          onChange={() =>
                            setFilters({
                              ...filters,
                              statusAviability: status
                            })
                          }
                        />
                        <label className="custom-control-label" htmlFor={status}>
                          {languageState.translation.STEPPER.FIRST_STEP_OPTIONS[status]}
                        </label>
                      </div>
                    </Col>
                  })
                }
              </CardBody>
            </Card>
            <Card className='card-filter'>
              <CardHeader>
                <h3 className="mb-0">
                  {languageState.translation.COMMON.TYPE}
                </h3>
              </CardHeader>
              <CardBody style={{ maxHeight: '400px', overflowY: 'auto' }}>
                {
                  professions.map((prof, index) => {
                    return <Col key={index} md="12">
                      <div className="custom-control custom-checkbox mb-3">
                        <input
                          className="custom-control-input"
                          id={prof}
                          type="checkbox"
                          checked={filters?.profession?.includes(prof)}
                          onChange={() => {
                            const selectedProfessions = filters?.profession || []
                            setFilters({
                              ...filters,
                              profession: selectedProfessions.includes(prof)
                                ? _.without(selectedProfessions, prof)
                                : [...selectedProfessions, prof]
                            })
                          }
                          }
                        />
                        <label className="custom-control-label" htmlFor={prof}>
                          {languageState.translation.STEPPER.FIRST_STEP_OPTIONS[prof]}
                        </label>
                      </div>
                    </Col>
                  })
                }
              </CardBody>
            </Card>
            <Card className='card-filter'>
              <CardHeader>
                <h3 className="mb-0">
                  {languageState.translation.COMMON.COUNTRY}
                </h3>
              </CardHeader>
              <CardBody>
                <div className="custom-control custom-checkbox mb-3">
                  {countries?.map(({ country }, index) => (
                    <Col md="12" key={index}>
                      <input
                        className="custom-control-input"
                        id={country}
                        type="checkbox"
                        checked={filters.country.includes(country)}
                        onChange={(e) => {
                          handleCountryChange(e, country);
                        }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={country}
                      >
                        {country}
                      </label>
                    </Col>
                  ))}
                </div>
              </CardBody>
            </Card>
            <Card className='card-filter'>
              <CardHeader>
                <h3 className="mb-0">
                  {languageState.translation.COMMON.EXPERIENCE_LEVEL}
                </h3>
              </CardHeader>

              <CardBody>
                <Col md="12">
                  <div className="custom-control custom-checkbox mb-3">
                    <input
                      className="custom-control-input"
                      id="customCheck3"
                      type="checkbox"
                      checked={experienceLevelString.includes('0-2')}
                      onChange={() => changeExperienceLevelsFilter('0-2')}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheck3"
                    >
                      0-2 {languageState.translation.COMMON.YEARS}{' '}
                    </label>
                  </div>
                </Col>
                <Col md="12">
                  <div className="custom-control custom-checkbox mb-3">
                    <input
                      className="custom-control-input"
                      id="customCheck4"
                      type="checkbox"
                      checked={experienceLevelString.includes('2-5')}
                      onChange={() => changeExperienceLevelsFilter('2-5')}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheck4"
                    >
                      2-5 {languageState.translation.COMMON.YEARS}
                    </label>
                  </div>
                </Col>
                <Col md="12">
                  <div className="custom-control custom-checkbox mb-3">
                    <input
                      className="custom-control-input"
                      id="customCheck5"
                      type="checkbox"
                      checked={experienceLevelString.includes('5-10')}
                      onChange={() => changeExperienceLevelsFilter('5-10')}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheck5"
                    >
                      5-10 {languageState.translation.COMMON.YEARS}
                    </label>
                  </div>
                </Col>
                <Col md="12">
                  <div className="custom-control custom-checkbox mb-3">
                    <input
                      className="custom-control-input"
                      id="customCheck6"
                      type="checkbox"
                      checked={experienceLevelString.includes('10-10')}
                      onChange={() => changeExperienceLevelsFilter('10-10')}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheck6"
                    >
                      10+ {languageState.translation.COMMON.YEARS}
                    </label>
                  </div>
                </Col>
              </CardBody>
            </Card>
            {/*  <Card>
              <CardHeader>
                <Row>
                  <h3 className="mb-0">
                    {languageState.translation.COMMON.HOURLY_RATE}
                  </h3>
                  <Badge className="mt-1 ml-4" color="secondary">
                    {minSlider} - {maxSlider}
                  </Badge>
                </Row>
              </CardHeader>
              <CardBody>
                <Range
                  marks={{
                    0: 0,
                    25: 25,
                    50: 50,
                    75: 75,
                    100: 100,
                  }}
                  count={2}
                  trackStyle={[
                    { backgroundColor: '#5e72e4' },
                    { backgroundColor: '#5e72e4' },
                  ]}
                  defaultValue={[0, 100]}
                  step={5}
                  onChange={(e) => onSliderChange(e)}
                />
                <Badge
                  className="mt-5 d-flex justify-content-center c-pointer btn-exprimary"
                  color="primary"
                  onClick={(e) =>
                    setFilters({
                      ...filters,
                      dailyRateMin: minSlider,
                      dailyRateMax: maxSlider,
                    })
                  }
                >
                  {languageState.translation.COMMON.BUTTONS.FILTER}
                </Badge>
              </CardBody>
            </Card>
            <Card className='card-filter'>
              <CardHeader>
                <h3 className="mb-0">
                  {languageState.translation.COMMON.CATEGORY}
                </h3>
              </CardHeader>
              <CardBody
                style={{
                  maxHeight: '400px',
                  overflowY: 'auto',
                }}
              >
                <Col md="12">
                  <div className="custom-control custom-checkbox mb-3">
                    <input
                      className="custom-control-input"
                      id={'category'}
                      type="checkbox"
                      checked={filters.categories.includes('All')}
                      onChange={(e) =>
                        changeCategoryHandler('All')
                      }
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={'category'}
                    >
                      {languageState.translation.CATEGORIES_FILTER.ALL}
                    </label>
                  </div>
                </Col>
                {(jobsState && jobsState.categories || []).map((category, index) => (
                  <Col md="12" key={index}>
                    <div className="custom-control custom-checkbox mb-3">
                      <input
                        className="custom-control-input"
                        id={'category' + index}
                        type="checkbox"
                        checked={filters.categories.includes(category.id)}
                        onChange={() =>
                          changeCategoryHandler(category.id)
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={'category' + index}
                      >
                        {category.label}
                      </label>
                    </div>
                  </Col>
                ))}
                {jobsState && jobsState.categories.length == 0 && (
                  <span>{languageState.translation.CONNECT.NO_CATEGORY}</span>
                )}
              </CardBody>
            </Card> */}

            <Col lg={12} md={12} sm={12} className='p-0 mb-3'>
              <Button className='w-100' color='primary' onClick={resetFilter} >{languageState.translation.COMMON.BUTTONS.RESET_FILTER}</Button>
            </Col>
          </Col>

          <Col xl={9} lg={9} md={8} sm={12}>
            {!users?.length && (
              <div className="d-flex justify-content-center  h-100 display-1 mt-6 mb-8 bigFontSize ">
                <div className=" p-5 text-center  rounded shadow-sm ">
                  <i className="fas fa-user-friends"></i>
                  <p className=" font-weight-bold display-4">
                    {noDataFound
                      ? languageState.translation.COMMON.USER_SEARCH_NO_RESULTS
                      : languageState.translation.CONNECT.YOU_HAVE_NO_USERS}
                  </p>
                </div>
              </div>
            )}
            <Row className='grid-data-users'>
              {users?.length &&
                users.map((user, index) => (
                  <Col key={index} className='p-0'>
                    <UserCard
                      openModalAddNote={() => {
                        setModalShow(true);
                        setUserId(user.id);
                      } }
                      openModalDelete={() => {
                        setModalDeleteShow(true);
                        setUserId(user.id);
                      } }
                      openModalAddUserTags={() => openModalAddUserTags(user)}
                      user={user}
                      key={user.id}
                      mode={mode}
                      openModalAddUserGroup={() => {
                        setModalAddGroupShow(true);
                        setUserId(user.id);
                      } } module={undefined}                    />
                  </Col>
                ))}
            </Row>
            {connectState.config?.totalItems > 8 && (
              <ButtonGroup className="d-flex justify-content-center gap-1">
                <Button
                  className=" flex-grow-0 rounded-circle paginate-button"
                  type="button"
                  color="primary"
                  onClick={() => prevPage()}
                  disabled={hasPrev()}
                >
                  <i className="fas fa-angle-left"></i>
                </Button>
                {paginationList &&
                  paginationList.map((data, index) => (
                    <Button
                      className={
                        connectState.config?.currentPage == data
                          ? '  flex-grow-0 rounded-circle paginate-button active shadow-lg'
                          : 'flex-grow-0 rounded-circle paginate-button shadow-lg'
                      }
                      color="primary"
                      key={index}
                      type="button"
                      onClick={() => getMoreUsers(data)}
                    >
                      {data}
                    </Button>
                  ))}

                {connectState.config?.totalPages -
                  connectState.config?.currentPage >
                  1 &&
                  connectState.config?.currentPage + 1 !==
                  connectState.config?.totalPages && (
                    <>
                      <span
                        style={{
                          alignSelf: 'center',
                        }}
                      >
                        ...
                      </span>
                      <Button
                        className={
                          connectState.config?.currentPage ==
                            connectState.config?.totalPages
                            ? '  flex-grow-0 rounded-circle paginate-button active shadow-lg'
                            : 'flex-grow-0 rounded-circle paginate-button shadow-lg'
                        }
                        color="primary"
                        type="button"
                        onClick={() =>
                          getMoreUsers(connectState.config?.totalPages)
                        }
                      >
                        {connectState.config?.totalPages}
                      </Button>
                    </>
                  )}
                <Button
                  className=" flex-grow-0 rounded-circle paginate-button "
                  type="button"
                  color="primary"
                  onClick={() => nextPage()}
                  disabled={hasNext()}
                >
                  <i className="fas fa-angle-right"></i>
                </Button>
              </ButtonGroup>
            )}
          </Col>
        </Row>
      </Container>
      <ModalNotes
        modalShow={modalShow}
        userid={userid}
        closeModal={() => {
          setModalShow(false);
        }}
      />
      <DeleteModal
        modalShow={modalDeleteShow}
        userid={userid}
        closeModal={() => {
          setModalDeleteShow(false);
        }}
      />
      <ModalAddToGroup
        modalShow={modalAddGroupShow}
        userId={userid}
        closeModal={() => {
          setModalAddGroupShow(false);
        }}
      />
      <ModalAddUserTags
        modalShow={tagModalShow}
        userid={userid}
        closeModal={() => {
          setTagModalShow(false);
        }}
      />
    </div>
  );
};

export default YourNetwork;
